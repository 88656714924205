import { BatchDetail, ProductVariantModel } from "@/interfaces/batch";
import { Location, LocationSalesLimits } from "@/interfaces/location";
import { Product } from "@/interfaces/product";
import { Strain } from "@/interfaces/strain";
import { metrcEnabled } from "@/router.utils";
import { CallbackPromise } from "@/types/types";
import { fnsFormatDate } from "@/utils/date-fns.utils";
import differenceInDays from "date-fns/differenceInDays";
import {
  HelixDatePickerComponent,
  HelixDatePickerOptions
} from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import { TranslateResult } from "vue-i18n";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./BasicInfo.template.vue";
/**
 * namespace to dispatch vuex actions and getters
 * @const string namespace
 */
const namespace: string = "ProductModule";

/**
 * batch basic info form component
 */
@Component({
  mixins: [Template],
  components: {
    HelixDatePickerComponent
  },
  filters: {
    simpleDate: (v: string) => (v ? fnsFormatDate(new Date(v)) : "")
  }
})
export default class BasicInfoComponent extends Vue {
  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;
  @Getter("limitConfig", { namespace: "AuthModule" })
  public limitConfig!: LocationSalesLimits[];

  public units: Array<{ label: string | TranslateResult; value: string }> = [];
  public noDateText = "no_date";
  public packageMenu: boolean = false;
  public packageWeightTypes: string[] = [];
  public metrcEnabled = metrcEnabled();
  public datePickerConfig: Partial<HelixDatePickerOptions> = {};
  public selectedProduct: Product | null = null;
  public isLoadingReplacements: boolean = false;

  @Prop({ required: true })
  public value!: ProductVariantModel;

  @Prop({ default: false })
  public hasModifyPermission!: boolean;

  /**
   * model to save
   * @var Batch batch
   */
  @Prop({ required: true, default: {} })
  protected batch!: BatchDetail;

  @Prop({ required: true })
  protected product!: Product;

  /**
   * property to search the strains in the autocomplete
   * @var string searchStrainField
   */
  protected searchStrainField: string | null = null;

  protected searchReplacementField: string | null = null;

  /**
   * autocomplete is loading state
   * @var boolean isLoadingStrains
   */
  protected isLoadingStrains: boolean = false;

  /**
   * list of strain to display in the autocomplete field
   * @var Strain[] strain
   */
  @Getter("strains", { namespace })
  protected strainItems!: Strain[];

  @Getter("products", { namespace })
  protected replacements!: Product[];

  /**
   * action to find the strains to the autocomplete list
   */
  @Action("findStrains", { namespace })
  private findStrainsAction!: CallbackPromise<void>;

  @Action("findReplacementProducts", { namespace })
  private findReplacementsAction!: CallbackPromise<void>;

  private debounceTime = 500;

  public get totalAvailableQuantity() {
    let totalAvailable = 0;
    if (this.batch && this.batch.on_rooms) {
      totalAvailable = this.batch.on_rooms.reduce((total, roomData) => {
        if (roomData.batch_fraction_status_type === "AVAILABLE") {
          total += roomData.quantity_value;
        }
        return total;
      }, 0);
    }
    return totalAvailable;
  }

  public get totalReservedQuantity() {
    let totalReserved = 0;
    if (this.batch && this.batch.on_rooms) {
      totalReserved = this.batch.on_rooms.reduce((total, roomData) => {
        if (roomData.batch_fraction_status_type === "RESERVED") {
          total += roomData.quantity_value;
        }
        return total;
      }, 0);
    }
    return totalReserved;
  }

  public get availableQuantityValue() {
    if (this.batch) {
      return this.totalAvailableQuantity + " " + this.batch.total_quantity_unit;
    } else {
      return 0;
    }
  }

  public get reservedQuantityValue() {
    if (this.batch) {
      return this.totalReservedQuantity + " " + this.batch.total_quantity_unit;
    } else {
      return "--";
    }
  }

  public get totalQunatityValue() {
    if (this.batch) {
      return (
        this.totalAvailableQuantity +
        this.totalReservedQuantity +
        " " +
        this.batch.total_quantity_unit
      );
    } else {
      return "--";
    }
  }

  public get expirationConfig(): Partial<HelixDatePickerOptions> {
    return {
      ...this.dateFormatted,
      "picker-options": {
        disabledDate: (date: Date) => {
          return differenceInDays(new Date(), date) > 0;
        }
      }
    };
  }

  public get dateFormatted() {
    return {
      placeholder: this.$t(this.noDateText).toString(),
      "value-format": "yyyy-MM-dd",
      format: "MM/dd/yyyy",
      disabled: this.hasModifyPermission
    };
  }

  public get requirePackageWeight() {
    if (this.packageWeightTypes) {
      return (
        this.selectedProduct &&
        this.packageWeightTypes.includes(this.selectedProduct.batch_type_id!)
      );
    }
    return false;
  }

  public get isCannabisCountable() {
    return (
      this.selectedProduct &&
      this.selectedProduct.marijuana &&
      !this.selectedProduct.requires_weighing
    );
  }

  public get model() {
    return cloneDeep(this.value);
  }

  public get secondaryIdRequired(): boolean {
    return !!(
      this.metrcEnabled &&
      this.selectedProduct &&
      this.selectedProduct.marijuana
    );
  }

  private debounceStrainSearch = debounce(
    async (scope: any, strainName: string) => {
      if (!strainName || strainName.length > 2) {
        this.isLoadingStrains = true;
        await scope.findStrainsAction({
          strainName,
          batchStrain: null
        });
        this.isLoadingStrains = false;
      }
    },
    this.debounceTime
  );

  private debounceProductSearch = debounce(
    async (scope: any, productName: string) => {
      if (!productName || productName.length > 2) {
        this.isLoadingReplacements = true;
        await scope.findReplacementsAction({
          productName,
          requiresWeighing: this.product!.requires_weighing,
          marijuana: this.product!.marijuana
        });
        this.isLoadingReplacements = false;
      }
    },
    this.debounceTime
  );

  public async changeProduct() {
    this.$emit("changeProduct", this.selectedProduct);
  }

  public emitModel() {
    this.$emit("input", this.model);
  }

  public get totalQuantity() {
    if (this.batch && this.batch.on_rooms) {
      const totalAvailable = this.batch.on_rooms.reduce(
        (acc, roomData) => {
          if (roomData.batch_fraction_status_type === "AVAILABLE") {
            acc.value += roomData.quantity_value;
            acc.unit = acc.unit || roomData.quantity_unit;
          }
          return acc;
        },
        { value: 0, unit: "" }
      );
      return `${totalAvailable.value} ${totalAvailable.unit}`;
    }
    return "--";
  }

  protected setPackageWeightLimit() {
    const cannabisRules = this.limitConfig.filter(
      r => r.type === "cannabis_product"
    );
    if (cannabisRules.length) {
      const packageConfig = cannabisRules.find(
        r =>
          r.group ===
          (this.currentLocation.location_type === "RETAIL_MEDICAL"
            ? "medicinal"
            : "recreational")
      );
      if (packageConfig) {
        this.packageWeightTypes = packageConfig.configs.reduce(
          (acc: string[], c) => {
            if (c.config.rule_field === "package" && c.config.batch_types) {
              acc = [...acc, ...c.config.batch_types];
            }
            return acc;
          },
          []
        );
      }
    }
  }
  /**
   * watcher to dispatch the findStrain action, when the autocomplete field is change
   * @param strainName
   */
  @Watch("searchStrainField")
  protected searchStrain(strainName: string) {
    if (!this.model.strain_id) {
      this.debounceStrainSearch(this, strainName);
    }
  }

  @Watch("sarchReplacementField")
  protected searchReplacement(productName: string) {
    if (this.selectedProduct!.name !== productName) {
      this.debounceProductSearch(this, productName);
    }
  }

  protected mounted() {
    const unwatch = this.$watch("product", () => {
      if (this.product) {
        this.selectedProduct = this.product;
        this.findReplacementsAction({
          requiresWeighing: this.product!.requires_weighing,
          marijuana: this.product!.marijuana,
          current: this.product
        });
        if (this.product.marijuana) {
          this.setPackageWeightLimit();
          this.findStrainsAction({
            strainName: "",
            batchStrain: this.model.strain || null
          });
        }
        unwatch();
      }
    });
    this.units = [
      {
        label: this.$t("limit.grams"),
        value: "g"
      },
      {
        label: this.$t("limit.milligrams"),
        value: "mg"
      },
      {
        label: this.$t("limit.ounces"),
        value: "oz"
      }
    ];
  }
}
