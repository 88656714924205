import { Product } from "@/interfaces/product";
import { Room } from "@/interfaces/room";
import { Strain } from "@/interfaces/strain";
import { MetrcItem } from "@/interfaces/traceability";
import { i18n } from "@/plugins/i18n";
import {
  FormField,
  FormFieldTypes,
  FormModel,
  TableFieldType,
  TableHeader
} from "helix-vue-components";
import { NewBatchFormModel } from "./model/batch-combine.model";

export const BatchCombineTableHeaders: TableHeader[] = [
  {
    label: i18n.t("product").toString(),
    value: "fatherItem.name",
    class: "tdt__headers__fieldLong",
    fieldComponent: TableFieldType.string
  },
  {
    label: i18n.t("batch_id").toString(),
    value: "batch_id",
    class: "tdt__headers__fieldLong",
    fieldComponent: TableFieldType.string
  },
  {
    label: i18n.t("cost_per_unit").toString(),
    value: "cost_per_unit",
    class: "tdt__headers__fieldShort",
    fieldComponent: TableFieldType.currency
  },
  {
    label: i18n.t("secondary_id").toString(),
    value: "tracking_id",
    class: "tdt__headers__fieldLong",
    fieldComponent: TableFieldType.string
  },
  {
    label: i18n.t("quantity").toString(),
    value: "room_quantity",
    class: "tdt__headers__fieldShort",
    fieldComponent: TableFieldType.string
  },
  {
    label: i18n.t("room").toString(),
    value: "rooms.[0].name",
    class: "tdt__headers__fieldShort",
    fieldComponent: TableFieldType.string
  },
  {
    label: i18n.t("batches.combine_quantity").toString(),
    value: "combine_quantity",
    class: "tdt__headers__fieldShort",
    fieldComponent: TableFieldType.numericField,
    validateFns: []
  }
];

export const BatchCombineTable = {
  headers: BatchCombineTableHeaders
};

export const newBatchFormMetadata: (
  roomsCallback: (d: { newValue: string }) => Room[] | null,
  strainsCallback: (
    d: { newValue: string; currentModel: NewBatchFormModel }
  ) => Promise<Strain[] | null>,
  productsCallback: (
    d: { newValue: Partial<Product> }
  ) => Promise<Product[] | null>,
  metrcListCallback: (
    d: { newValue: Partial<MetrcItem> }
  ) => Promise<MetrcItem[] | null>,
  metrcOn: boolean,
  marijuana: boolean
) => FormField[] = (
  roomsCallback: (d: { newValue: string }) => Room[] | null,
  strainsCallback: (
    d: { newValue: string; currentModel: NewBatchFormModel }
  ) => Promise<Strain[] | null>,
  productsCallback: (
    d: { newValue: Partial<Product> }
  ) => Promise<Product[] | null>,
  metrcListCallback: (
    d: { newValue: Partial<MetrcItem> }
  ) => Promise<MetrcItem[] | null>,
  metrcOn: boolean,
  marijuana: boolean
) => {
  const defaultFormMetadata: FormField[] = [
    {
      component: FormFieldTypes.asyncAutocomplete,
      label: i18n.t("batch_combine.product"),
      name: "productObject",
      options: {
        validationRules: ["required"],
        placeholder: i18n.t("batch_combine.product_placeholder"),
        row: 1,
        flex: 4,
        associationValue: "sku",
        associationText: "name",
        selectOptions: productsCallback,
        vuetifyProps: {
          "return-object": true,
          "item-value": "sku"
        }
      }
    },
    {
      component: FormFieldTypes.asyncAutocomplete,
      label: i18n.t("batch_combine.strain"),
      name: "strain",
      options: {
        validationRules: [""],
        placeholder: i18n.t("batch_combine.strain_placeholder"),
        row: 1,
        flex: 4,
        associationValue: "id",
        associationText: "name",
        selectOptions: strainsCallback
      }
    },
    {
      component: FormFieldTypes.asyncAutocomplete,
      label: i18n.t("batch_combine.room"),
      name: "room",
      options: {
        validationRules: ["required"],
        placeholder: i18n.t("batch_combine.room_placeholder"),
        row: 1,
        flex: 4,
        associationValue: "id",
        associationText: "name",
        selectOptions: roomsCallback
      }
    },
    {
      component: FormFieldTypes.asyncAutocomplete,
      label: i18n.t("batch_combine.metrc_item"),
      name: "metrc_item",
      options: {
        validationRules: ["required"],
        placeholder: i18n.t("batch_combine.metrc_item_placeholder"),
        vuetifyProps: {
          returnObject: true
        },
        visibleCondition: (model: FormModel) => {
          return metrcOn && marijuana;
        },
        row: 1,
        flex: 4,
        associationText: "name",
        selectOptions: metrcListCallback
      }
    }
  ];

  return defaultFormMetadata;
};
