var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading())?_c('div',[_c('v-layout',{staticClass:"batch__id text-uppercase",attrs:{"row":"","text-xs-center":"","justify-center":""}},[(_vm.bioTrackTraceEnabled && _vm.batch.biotrack_traceability_id === '' && _vm.getProductData.marijuana)?_c('v-tooltip',{attrs:{"top":""}},[_c('v-icon',{attrs:{"slot":"activator","size":"22","color":"red"},slot:"activator"},[_vm._v("fas fa-exclamation-triangle")]),_vm._v(" "),_c('span',[_vm._v(" "+_vm._s(_vm.$t("biotrack_traceability.traceid_error")))])],1):_vm._e(),_vm._v(" "),_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("batch_id"))+" ")]),_vm._v(" "),(_vm.bioTrackTraceEnabled && _vm.batch.biotrack_traceability_id !== '')?_c('span',{staticClass:"font-weight-bold grey--text text--darken-1 mr-4"},[_vm._v("\n      "+_vm._s(_vm.batch.biotrack_traceability_id))]):_c('span',{staticClass:"font-weight-bold grey--text text--darken-1 mr-4"},[_vm._v("\n      "+_vm._s(_vm.batch.batch_uid))]),_vm._v(" "),(
        _vm.inventorySetting &&
        _vm.inventorySetting.stock_identification_type === 'NDC_NUMBER'
      )?[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("inventory_settings_view.ndc_number"))+"\n      ")]),_vm._v(" "),_c('span',{staticClass:"font-weight-bold grey--text text--darken-1 mr-4"},[_vm._v("\n        "+_vm._s(_vm.variant.ndc_number || "-"))])]:_vm._e(),_vm._v(" "),(
        _vm.inventorySetting &&
        _vm.inventorySetting.stock_identification_type === 'SECONDARY_ID'
      )?[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("secondary_id"))+" ")]),_vm._v(" "),_c('span',{staticClass:"font-weight-bold grey--text text--darken-1 mr-4"},[_vm._v("\n        "+_vm._s(_vm.batch.tracking_id || "-"))])]:_vm._e(),_vm._v(" "),_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("batch_combine.product_placeholder"))+" ")]),_vm._v(" "),_c('span',{staticClass:"font-weight-bold grey--text text--darken-1 mr-4"},[_vm._v("\n      "+_vm._s(_vm.variant.name))]),_vm._v(" "),_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("inventory.batch_type"))+" ")]),_vm._v(" "),_c('span',{staticClass:"font-weight-bold grey--text text--darken-1"},[_vm._v("\n      "+_vm._s(_vm.variant.batch_type.name))])],2),_vm._v(" "),_c('v-layout',{staticClass:"batch__actions",attrs:{"row":"","text-xs-center":"","justify-center":""}},[(_vm.hasPermission(_vm.policyList.adjustBatch))?_c('v-btn',{attrs:{"disabled":_vm.batch.batch_status_type === _vm.outgoingTransfer ||(_vm.bioTrackTraceEnabled && _vm.batch.biotrack_traceability_id === '' && !!_vm.getProductData.marijuana)||  _vm.loading()},on:{"click":function($event){_vm.actionBatch('adjust')}}},[_vm._v(_vm._s(_vm.$t("adjust")))]):_vm._e(),_vm._v(" "),(_vm.hasPermission(_vm.policyList.convertBatch))?_c('v-btn',{attrs:{"disabled":_vm.batch.batch_status_type === _vm.outgoingTransfer ||
        _vm.loading() ||
        !_vm.batch.total_quantity_value ||
        _vm.isReserved || (_vm.bioTrackTraceEnabled && _vm.batch.biotrack_traceability_id === '' && !!_vm.getProductData.marijuana)},on:{"click":function($event){_vm.actionBatch('convert')}}},[_vm._v(_vm._s(_vm.$t("convert")))]):_vm._e(),_vm._v(" "),(_vm.hasPermission(_vm.policyList.moveBatch))?_c('v-btn',{attrs:{"disabled":_vm.batch.batch_status_type === _vm.outgoingTransfer ||
        _vm.loading() ||
        !_vm.batch.total_quantity_value ||
        _vm.isReserved || (_vm.bioTrackTraceEnabled && _vm.batch.biotrack_traceability_id === '' && !!_vm.getProductData.marijuana)},on:{"click":function($event){_vm.actionBatch('batch-move')}}},[_vm._v(_vm._s(_vm.$t("move")))]):_vm._e(),_vm._v(" "),(_vm.hasPermission(_vm.policyList.splitBatch))?_c('v-btn',{attrs:{"disabled":_vm.batch.batch_status_type === _vm.outgoingTransfer ||
        _vm.loading() ||
        !_vm.batch.total_quantity_value ||
        _vm.isReserved || (_vm.bioTrackTraceEnabled && _vm.batch.biotrack_traceability_id === '' && !!_vm.getProductData.marijuana)},on:{"click":function($event){_vm.actionBatch('split')}}},[_vm._v(_vm._s(_vm.$t("split")))]):_vm._e(),_vm._v(" "),(_vm.hasPermission(_vm.policyList.printBatchBarcodes))?_c('v-btn',{attrs:{"disabled":_vm.loading()},on:{"click":_vm.barcodeCallback}},[_vm._v(_vm._s(_vm.$t("print_barcode")))]):_vm._e(),_vm._v(" "),(_vm.hasPermission(_vm.policyList.printBatchLabels))?_c('v-btn',{attrs:{"disabled":_vm.loading()},on:{"click":_vm.labelCallback}},[_vm._v(_vm._s(_vm.$t("print_labels")))]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"progress-wrap"},[_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading()),expression:"loading()"}],staticClass:"my-0",attrs:{"height":"3","color":"primary","indeterminate":true}})],1)],1):_c('v-progress-linear',{staticClass:"progressBar",attrs:{"height":"3","color":"primary","indeterminate":true}})}
var staticRenderFns = []

export { render, staticRenderFns }