import { AdditionalInfoModel, BatchDetail } from "@/interfaces/batch";
import differenceInDays from "date-fns/differenceInDays";
import cloneDeep from "lodash/cloneDeep";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./AdditionalInfo.template.vue";

@Component({
  mixins: [Template]
})
export default class AdditionalInfoComponent extends Vue {
  @Prop({ required: true })
  public value!: AdditionalInfoModel;
  @Prop({ required: true })
  public batch!: BatchDetail;
  @Prop({ default: false })
  public hasModifyPermission!: boolean;

  public humanizeDate(date: string) {
    if (date) {
      return differenceInDays(new Date(), new Date(date));
    }
    return "0";
  }

  public get totalAvailableQuantity() {
    let totalAvailable = 0;
    if (this.batch.on_rooms) {
      const rooms = this.batch.on_rooms.filter(
        room => room.batch_fraction_status_type === "AVAILABLE"
      );
      rooms.forEach(room => (totalAvailable += room.quantity_value));
    }
    return totalAvailable;
  }

  public get totalReservedQuantity() {
    let totalReserved = 0;
    if (this.batch.on_rooms) {
      const rooms = this.batch.on_rooms.filter(
        room => room.batch_fraction_status_type === "RESERVED"
      );
      rooms.forEach(room => (totalReserved += room.quantity_value));
    }
    return totalReserved;
  }

  public get availableQuantityValue() {
    return this.totalAvailableQuantity + " " + this.batch.total_quantity_unit;
  }

  public get reservedQuantityValue() {
    return this.totalReservedQuantity + " " + this.batch.total_quantity_unit;
  }

  public get totalQunatityValue() {
    return (
      this.totalAvailableQuantity +
      this.totalReservedQuantity +
      " " +
      this.batch.total_quantity_unit
    );
  }

  public get model() {
    return cloneDeep(this.value);
  }

  public emitChanges() {
    this.$emit("input", this.model);
  }

  public get rooms() {
    const rooms: any[] = [];
    if (this.batch.on_rooms) {
      const uniqueRooms = [
        ...new Set(this.batch.on_rooms.map(room => room.inventory_location_id))
      ];
      if (uniqueRooms.length) {
        uniqueRooms.forEach(roomId => {
          const availableRoom = this.batch.on_rooms.filter(
            room =>
              roomId === room.inventory_location_id &&
              room.batch_fraction_status_type === "AVAILABLE"
          )[0];
          const reservedRoom = this.batch.on_rooms.filter(
            room =>
              roomId === room.inventory_location_id &&
              room.batch_fraction_status_type === "RESERVED"
          )[0];
          rooms.push({
            name: availableRoom
              ? availableRoom.inventory_location.name
              : reservedRoom
              ? reservedRoom.inventory_location.name
              : "",
            available: availableRoom ? availableRoom.quantity_value : 0,
            reserved: reservedRoom ? reservedRoom.quantity_value : 0,
            unit: availableRoom ? availableRoom.quantity_unit : "",
            availableData: availableRoom ? availableRoom : null
          });
        });
      }
    }

    return rooms;
  }
}
