import { fnsBasicDate } from "@/filters/date-fns.filter";
import {
  BatchSample,
  BatchSamplesModel,
  defaultLaboratoryResult,
  Laboratory,
  LaboratoryResult,
  SampleResultProfile
} from "@/interfaces/batch";
import { labResultTable } from "@/metadata/batch";
import { i18n } from "@/plugins/i18n";
import { batchService } from "@/services/batch.service";
import { Callback } from "@/types/types";
import {
  HelixDatePickerComponent,
  HelixDatePickerOptions,
  TableAction,
  TableComponent,
  TableHeader,
  TablePagination,
  TableSuccessModalResponse
} from "helix-vue-components";
import chunk from "lodash/chunk";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import { Validator } from "vee-validate";
import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./ModifyResults.template.vue";
const namespace: string = "BatchModule";

@Component({
  mixins: [Template],
  components: {
    TableComponent,
    HelixDatePickerComponent
  },
  filters: { fnsBasicDate }
})
export default class ModifyResultsModalComponent extends Vue {
  @Inject("$validator") public $validator!: Validator;

  @Prop({ required: true })
  public batchSamples!: BatchSample | null;

  @Action("findSampleResultProfiles", { namespace })
  public findSampleResultProfilesAction!: Callback;
  public warnMessageDisplay: boolean = false;

  @Getter("sampleResultProfiles", { namespace })
  public sampleResultProfiles!: SampleResultProfile[];
  public selectedSampleResultProfiles: SampleResultProfile[] = [];
  public laboratories: Laboratory[] = [];
  public hasChanged = false;
  public defaultLabList: Laboratory[] = [];
  public headers: TableHeader[] = labResultTable;
  public currentLabResult: LaboratoryResult = { ...defaultLaboratoryResult };
  public model: BatchSamplesModel = {
    laboratory_id: null,
    testing_date: null,
    results: []
  };
  public pagination: TablePagination = {
    totalItems: 0,
    itemsPerPage: 3,
    lastPage: this.dataTablet.length,
    itemsPerPageOptions: [],
    currentPage: 1,
    from: 1,
    to: 3
  };
  public paginationDefault: TablePagination = cloneDeep(this.pagination);
  public selectedElement: HTMLElement | null = null;
  public previousStyle = "";
  public searchLaboratoriesField: string | null = null;
  public isLoadingResultProfiles: boolean = false;
  public isLoadingLaboratories: boolean = false;
  // Contains the list of test type(breakdown)
  public testingType = [
    { value: "MOISTURE_CONTENT", text: "Moisture Content" },
    { value: "FOREIGN_MATTER_INSPECTION", text: "Foreign Matter Inspection" },
    { value: "MICROBIOLOGICAL_SCREENING", text: "Microbiological Screening" },
    { value: "RESIDUAL_SOLVENT", text: "Residual Solvent" },
    { value: "MYCOTOXIN_SCREENING", text: "Mycotoxin Screening" },
    { value: "PESTICIDE_RESIDUE", text: "Pesticide Residue" },
    { value: "HEAVY_METAL", text: "Heavy Metal" },
    { value: "POTENCY", text: "Potency" },
    { value: "TERPENE", text: "Terpene" }
  ];
  // Contains the list of test type which have units Pass or Fail
  public statusTestingType = ["RESIDUAL_SOLVENT", "PESTICIDE_RESIDUE"];
  public selectedTestingType: string = "";
  public test = 0;
  public errorMessage = "";
  public rowActions: TableAction[] = [
    {
      icon: "fal fa-pencil",
      action: (item: LaboratoryResult, index: number) =>
        this.selectForEdition(item, index)
    },
    {
      icon: "fal fa-trash-alt",
      modalActions: {
        modalNumber: 1,
        modalQuestion: String(i18n.t("inventory.lab_result_delete_confirm")),
        modalSuccessText: String(i18n.t("yes")),
        modalSuccessAction: (arg: TableSuccessModalResponse) => {
          this.deleteResult(arg).finally(arg.unselectModal);
        },
        modalCancelText: String(i18n.t("no"))
      }
    }
  ];
  // For disabling future dates from selecting
  public datePickerOption: Partial<HelixDatePickerOptions> = {
    required: true,
    name: "date",
    "value-format": "yyyy-MM-dd",
    "picker-options": {
      disabledDate(date) {
        const today = new Date();
        today.setDate(today.getDate());
        return date > today;
      }
    }
  };

  protected debounceLabSearch = debounce(
    async (scope: ModifyResultsModalComponent, labName: string) => {
      if (!labName || labName.length > 2) {
        scope.isLoadingLaboratories = true;
        if (!labName && scope.defaultLabList.length) {
          scope.laboratories = scope.defaultLabList;
          scope.isLoadingLaboratories = false;
          return;
        }
        scope.laboratories = await batchService.getLaboratories({
          "q[name_contains]": labName
        });
        if (!labName) {
          scope.defaultLabList = cloneDeep(scope.laboratories);
        }
        scope.isLoadingLaboratories = false;
      }
    },
    500
  );

  @Watch("searchLaboratoriesField")
  public async searchLaboratories(labName: string) {
    if (!labName) {
      this.model.laboratory_id = null;
    }
    if (!this.model.laboratory_id) {
      this.debounceLabSearch(this, labName);
    }
  }

  // condition to disable value field before filling UoM
  public get uomErrorCondition() {
    if (
      !!this.selectedTestingType &&
      !this.currentLabResult.unit_representation &&
      this.warnMessageDisplay
    ) {
      return true;
    } else {
      return false;
    }
  }

  public async setSampleProfileId() {
    this.currentLabResult.profile_id =
      (this.currentLabResult.profile! && this.currentLabResult.profile!.id) ||
      -1;
    if (!this.currentLabResult.unit_representation) {
      this.warnMessageDisplay = true;
    }
  }

  public get resultsDone() {
    const resultsDone = this.model.results.map((result: LaboratoryResult) => {
      return result.profile!.type;
    });
    return resultsDone;
  }

  public get dataTablet() {
    return (
      (this.pagination &&
        chunk(this.model.results, this.pagination.itemsPerPage)[
          this.pagination.currentPage - 1
        ]) ||
      []
    );
  }

  public onChangePage(pagination: TablePagination) {
    this.pagination.currentPage = pagination.currentPage;
    const from =
      (pagination.currentPage - 1) * this.pagination.itemsPerPage + 1;
    const to =
      (pagination.currentPage - 1) * this.pagination.itemsPerPage +
      this.dataTablet.length;
    this.pagination.totalItems = this.model.results.length;
    this.pagination.from = (from > 0 && from) || 0;
    this.pagination.to = (to > 0 && to) || 0;
  }

  public formatResult(value: number) {
    return value ? +value.toString().match(/(\d*.\d{0,3})/)![0] : +value;
  }

  public async addNewResult() {
    const pickerIsValid = await (this.$refs[
      "hlx-datepicker"
    ] as HelixDatePickerComponent).validate();
    const validator = await this.$validator.validateAll();

    if (validator && pickerIsValid) {
      this.model.results = this.model.results.filter(
        result => result.profile!.type !== this.currentLabResult.profile!.type
      );
      this.model.results.unshift({
        ...this.currentLabResult,
        value: this.formatResult(+this.currentLabResult.value!)
      });
      this.currentLabResult = cloneDeep(defaultLaboratoryResult);
      this.hasChanged = true;
      this.onChangePage(this.paginationDefault);
      await this.$validator.reset();
    }
    if (this.selectedElement) {
      this.selectedElement.style.cssText = this.previousStyle;
    }
    this.selectedElement = null;
  }

  public async deleteResult(rowData: TableSuccessModalResponse) {
    const results = cloneDeep(this.model.results);
    const index = results.findIndex(
      item => item.profile_id === rowData.item.profile_id
    );
    if (index !== -1) {
      results.splice(index, 1);
      this.model.results = results;
      this.currentLabResult = defaultLaboratoryResult;
      const setPagination = {
        ...this.paginationDefault,
        currentPage:
          (this.dataTablet.length && this.pagination.currentPage) ||
          this.pagination.currentPage - 1
      };
      this.currentLabResult = cloneDeep(defaultLaboratoryResult);
      this.hasChanged = true;
      this.onChangePage(setPagination);
    }
  }

  public selectForEdition(itemSelected: LaboratoryResult, index: number) {
    this.warnMessageDisplay = false;
    this.selectedSampleResultProfiles = [];
    if (this.selectedElement) {
      this.selectedElement!.style.cssText = this.previousStyle;
    }
    if (itemSelected !== this.currentLabResult) {
      this.selectedTestingType = itemSelected!.profile!.breakdown!;
      this.currentLabResult = itemSelected;
      this.selectedSampleResultProfiles = this.sampleResultProfiles.filter(
        item => item.breakdown === this.selectedTestingType
      );
      this.selectedElement = document.getElementById(
        `tdt__data__headers${index}`
      );
      this.previousStyle = this.selectedElement!.style.cssText || "";
      this.selectedElement!.style.cssText =
        "background:rgba(0, 153, 255, 0.5) !important";
    } else {
      this.currentLabResult = defaultLaboratoryResult;
    }
    // reset validation
    this.$nextTick(() => {
      this.errors.clear();
      this.$nextTick(() => {
        this.$validator.reset();
      });
    });
  }

  public cancelEdit() {
    this.currentLabResult = cloneDeep(defaultLaboratoryResult);
    this.selectedElement!.style.cssText = this.previousStyle;
    this.selectedElement = null;
  }

  public confirmResults() {
    if (!!this.model.laboratory_id && !!this.model.testing_date) {
      this.model.results.map(data => {
        if (this.statusTestingType.includes(data.profile!.breakdown!)) {
          data.unit_representation =
            data.unit_representation === "Pass" ||
            data.unit_representation === "0"
              ? "0"
              : "1";
        }
      });
      this.$emit("resolve", {
        changed: this.hasChanged,
        samples: this.model
      });
    }
  }

  public onClose() {
    this.$emit("reject");
  }

  public validateNumber(value?: string) {
    if (!this.selectedTestingType) {
      return;
    }
    const units = "mg/g,mg,CFU/g,ppm,µg/kg";
    // Following condition is used to restric user to enter only 7 digits and 3 decimals
    // if (units.includes(this.currentLabResult!.unit_representation!)) {
    if (value && !/^[0-9]{0,7}(?:\.[0-9]{1,3})?$/.test(value)) {
      this.test += 1;
      this.$nextTick(() => {
        (this.$refs.amount_input as HTMLElement).focus();
      });
      setTimeout(() => {
        this.errorMessage = "";
      }, 2500);
      this.errorMessage = String(i18n.t("validation_errors.error-1"));
    } else {
      this.errorMessage = "";
      this.currentLabResult.value = Number(value);
    }
    // }
    // Following condition is used to restric user to  max upto 100
    // else if (this.selectedTestingType === "MOISTURE_CONTENT") {
    //   if (value && +value > 100) {
    //     this.test += 1;
    //     this.$nextTick(() => {
    //       (this.$refs.amount_input as HTMLElement).focus();
    //     });
    //     setTimeout(() => {
    //       this.errorMessage = "";
    //     }, 2500);
    //     this.errorMessage = String(i18n.t("validation_errors.error-2"));
    //   } else {
    //     this.errorMessage = "";
    //     this.currentLabResult.value = Number(value);
    //   }
    // }
    // Following condition is used to restric user to enter  max upto 100 and 2 decimals
    // else {
    //   if (+value! > 100) {
    //     this.test += 1;
    //     this.$nextTick(() => {
    //       (this.$refs.amount_input as HTMLElement).focus();
    //     });
    //     setTimeout(() => {
    //       this.errorMessage = "";
    //     }, 2500);
    //     this.errorMessage = String(i18n.t("validation_errors.error-3"));
    //   } else if (
    //     +value! <= 100 &&
    //     !/^[0-9]{0,3}(?:\.[0-9]{1,2})?$/.test(value!)
    //   ) {
    //     this.test += 1;
    //     this.$nextTick(() => {
    //       (this.$refs.amount_input as HTMLElement).focus();
    //     });
    //     setTimeout(() => {
    //       this.errorMessage = "";
    //     }, 2500);
    //     this.errorMessage = String(i18n.t("validation_errors.error-3"));
    //   } else {
    //     this.errorMessage = "";
    //     this.currentLabResult.value = Number(value);
    //   }
    // }
  }

  public validateDecimal(value: KeyboardEvent) {
    // Following condition is used to restric user from entering decimals
    // if (this.selectedTestingType === "MOISTURE_CONTENT" && value.key === ".") {
    //   setTimeout(() => {
    //     this.errorMessage = "";
    //   }, 2500);
    //   this.errorMessage = String(i18n.t("validation_errors.error-2"));
    //   value.preventDefault();
    // }
  }

  public get getDataTablet() {
    const dataTablet = cloneDeep(this.dataTablet);
    dataTablet.map(data => {
      if (this.statusTestingType.includes(data.profile!.breakdown!)) {
        data.value = "";
        data.unit_representation =
          data.unit_representation === "0" ||
          data.unit_representation === "Pass"
            ? "Pass"
            : "Fail";
      } else {
        data.value = +parseFloat(String(data.value)).toFixed(3);
      }
    });
    return dataTablet;
  }

  public units() {
    if (!this.selectedTestingType) {
      return [];
    }
    if (
      this.selectedTestingType === "TERPENE" ||
      this.selectedTestingType === "POTENCY"
    ) {
      return ["mg/g", "mg", "%"];
    }
    if (
      this.selectedTestingType === "FOREIGN_MATTER_INSPECTION" ||
      this.selectedTestingType === "MOISTURE_CONTENT"
    ) {
      return ["%"];
    }
    if (this.selectedTestingType === "MICROBIOLOGICAL_SCREENING") {
      return ["CFU/g"];
    }
    if (this.selectedTestingType === "MYCOTOXIN_SCREENING") {
      return ["µg/kg"];
    }
    if (this.selectedTestingType === "HEAVY_METAL") {
      return ["ppm"];
    }
    return ["Pass", "Fail"];
  }
  public changeUnit() {
    this.currentLabResult.value = null;
    this.currentLabResult.unit_representation
      ? (this.warnMessageDisplay = false)
      : (this.warnMessageDisplay = true);
  }

  protected onChangeTestingType() {
    this.isLoadingResultProfiles = true;
    this.currentLabResult.unit_representation = "";
    this.currentLabResult.value = null;
    this.selectedSampleResultProfiles = this.sampleResultProfiles.filter(
      index => index.breakdown === this.selectedTestingType
    );
    this.$validator.reset();
    setTimeout(() => {
      this.isLoadingResultProfiles = false;
    }, 100);
  }
  protected async mounted() {
    if (this.batchSamples) {
      const clone = cloneDeep(this.batchSamples);
      this.model = {
        laboratory_id: clone.laboratory_id,
        testing_date: clone.testing_date!,
        results: clone.results
      };
      this.pagination.totalItems = this.batchSamples.results.length;
    }
    this.debounceLabSearch(
      this,
      (this.batchSamples &&
        this.batchSamples.laboratory &&
        this.batchSamples.laboratory.name) ||
        ""
    );
    if (!this.sampleResultProfiles.length) {
      this.isLoadingResultProfiles = true;
      await this.findSampleResultProfilesAction();
      this.isLoadingResultProfiles = false;
    }
  }
}
