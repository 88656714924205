import NextTagComponent from "@/components/sharedComponents/nextTag/NextTag.component";
import { CustomBatch } from "@/interfaces/batch";
import { Product } from "@/interfaces/product";
import { Room } from "@/interfaces/room";
import { Strain } from "@/interfaces/strain";
import { MetrcItem } from "@/interfaces/traceability";
import { metrcEnabled } from "@/router.utils";
import { productService } from "@/services/product.service";
import { strainService } from "@/services/strain.service";
import { getParentSKU } from "@/utils/batch-actions.utils";
import { ungroup } from "@/utils/convert.utils";
import { mathJs } from "@/utils/math.utils";
import { DynamicFormComponent, FormField } from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import isObject from "lodash/isObject";
import reduce from "lodash/reduce";
import size from "lodash/size";
import uniqBy from "lodash/uniqBy";
import { Component, Prop, Vue } from "vue-property-decorator";
import BatchListSelecterComponent from "../../common/BatchListSelecter/BatchListSelecter.component";
import { newBatchFormMetadata } from "../declarations";
import { NewBatchFormModel, Result } from "../model/batch-combine.model";
import Template from "./CombineForm.template.vue";

@Component({
  mixins: [Template],
  components: {
    DynamicFormComponent,
    NextTagComponent,
    BatchListSelecterComponent
  }
})
export default class CombineFormComponent extends Vue {
  @Prop({ required: true })
  public batchList!: {
    [batchID: string]: CustomBatch[];
  };

  @Prop({ required: true })
  public marijuana!: boolean;

  @Prop({ required: true })
  public unit!: string;

  @Prop({ required: true })
  public value!: NewBatchFormModel;

  @Prop({ required: true })
  public rooms!: Room[];

  @Prop({ default: () => [] })
  public results!: Result[];
  public isLoadingMetrc: boolean = false;

  @Prop({ default: () => false })
  public loading!: boolean;
  /**
   * Form binding
   */
  public form: DynamicFormComponent | null = null;

  public fieldsConfig: FormField[] = [];
  public metrcList: MetrcItem[] = [];
  public defaultMetrcList: MetrcItem[] = [];
  public products: Product[] = [];
  public defaultProducts: Product[] = [];

  public strains: Strain[] = [];
  public defaultStrains: Strain[] = [];

  public commonBatchData: CustomBatch | null = null;
  public metrcEnabled = metrcEnabled();
  public hasClearedMetrcItems = false;

  public updateModel = debounce((data, context) => {
    context.$emit("input", {
      ...data.model,
      strain:
        (data.model.productObject && data.model.productObject.strain_id) ||
        data.model.strain,
      product:
        (data.model.productObject && data.model.productObject.sku) || null
    });
  }, 600);
  public get model() {
    return cloneDeep(this.value);
  }

  get combinationValue(): string {
    const sumQuantity = reduce(
      this.batchList,
      (sum, batches) => {
        for (const batch of batches) {
          sum = mathJs.add!(
            mathJs.bignumber!(sum),
            mathJs.bignumber!(batch.room_quantity)
          ) as number;
        }
        return sum;
      },
      0
    );

    return sumQuantity + this.unit;
  }
  public onCombine() {
    const form = this.$refs["new-batch-form"] as DynamicFormComponent;
    form.submit().then(result => {
      if (result.valid) {
        form.resetValidations();
        this.$emit("onCombine");
      }
    });
  }

  public onTrackingIdChange(trackingId: string) {
    this.model.secondaryID = trackingId;
    this.$emit("input", this.model);
  }

  public async created() {
    this.fieldsConfig = newBatchFormMetadata(
      () => this.rooms,
      this.getStrains(),
      this.getProducts(),
      this.getMetrcItemList(),
      this.metrcEnabled,
      this.marijuana
    );

    this.model.quantity = parseFloat(this.combinationValue);
  }

  /**
   * Autoselect the strain when selecting a product.
   * @param data: { model: NewBatchFormModel; modelField: string; }
   */
  public async onChange(data: {
    model: NewBatchFormModel;
    modelField: string;
  }) {
    // Handle for clear on Metrc Item
    if (data.modelField === "metrc_item" && !data.model.metrc_item) {
      this.hasClearedMetrcItems = true;
    } else {
      this.hasClearedMetrcItems = false;
    }
    this.updateModel(data, this);
  }

  public getStrains(): (
    scope: {
      newValue: string | number;
      currentModel: NewBatchFormModel;
    }
  ) => Promise<Strain[] | null> {
    return async (scope: {
      newValue: string | null | number;
      currentModel: NewBatchFormModel;
    }) => {
      if (typeof scope.newValue !== "number") {
        if (this.defaultStrains.length && !scope.newValue) {
          this.strains = this.defaultStrains;
          return this.strains;
        }
        const strains = await strainService.get({
          sort: "name",
          "q[name_contains]": scope.newValue || "",
          per_page: 10
        });
        if (!this.defaultStrains.length && !scope.newValue) {
          this.defaultStrains = strains;
        }
        this.strains = strains;
      } else {
        // This case applies only when product changed and has strain_id
        // to search only for that specific strain.
        const loadedStrain = this.strains.filter(s => s.id === scope.newValue!);

        if (!loadedStrain.length) {
          const strain = await strainService.getStrain(String(scope.newValue));
          this.strains = [strain, ...this.strains];
          this.$emit("input", {
            ...scope.currentModel,
            strain: scope.currentModel.strain
          });
        }
      }

      return this.strains;
    };
  }

  protected checkForSameProduct() {
    if (size(this.batchList)) {
      const flatBatches = ungroup(this.batchList);
      const unique = uniqBy(flatBatches, item => {
        return getParentSKU(item.product_sku!);
      });
      if (unique.length === 1) {
        this.commonBatchData = unique[0];
      }
    }
  }

  private getMetrcItemList(): (
    scope: {
      newValue: Partial<MetrcItem> | null;
    }
  ) => Promise<MetrcItem[] | null> {
    return async (scope: { newValue: Partial<MetrcItem> | null | string }) => {
      if (!this.metrcEnabled || !this.marijuana) {
        return [];
      }
      const searchTerm: string =
        (isObject(scope.newValue) && scope.newValue.name!) ||
        (scope.newValue && String(scope.newValue)) ||
        "";
      if (!this.model.product) {
        this.$emit("input", {
          ...this.model,
          metrc_item: null
        });
      }
      if (
        searchTerm &&
        this.model.metrc_item &&
        searchTerm === this.model.metrc_item.name &&
        this.metrcList.length
      ) {
        return this.metrcList;
      }

      if (!searchTerm && this.defaultMetrcList.length) {
        this.metrcList = this.defaultMetrcList;
        return this.metrcList;
      }

      this.metrcList = await productService.getMetrcList(searchTerm);
      if (this.model.product && !scope.newValue && !this.hasClearedMetrcItems) {
        const metrcItem = await productService.getProductMetrc(
          this.model.product
        );
        if (metrcItem) {
          this.metrcList.push(metrcItem);
          this.$emit("input", {
            ...this.model,
            metrc_item: metrcItem,
            metrcList: this.metrcList
          });
        }
      }
      if (!this.defaultMetrcList.length && !searchTerm) {
        this.defaultMetrcList = cloneDeep(this.metrcList);
      }
      return this.metrcList;
    };
  }

  private getProducts(): (
    scope: {
      newValue: Partial<Product> | null;
    }
  ) => Promise<Product[] | null> {
    return async (scope: { newValue: Partial<Product> | null }) => {
      // If there is a selected value already, do not make the request,
      // just auto select one of the preloaded options.

      this.getMetrcItemList()({
        newValue: scope.newValue ? this.model.metrc_item : null
      });
      if (scope.newValue && scope.newValue.sku === this.model.product) {
        return this.products;
      }

      if (scope.newValue === null) {
        this.checkForSameProduct();

        if (this.commonBatchData) {
          scope.newValue = { name: this.commonBatchData.product_name };
        }
      }

      if (!scope.newValue && this.defaultProducts.length) {
        this.products = this.defaultProducts;
        return this.products;
      }
      this.products = await productService.get({
        sort: "name",
        per_page: 10,
        "q[name_contains]":
          (scope.newValue && scope.newValue.name) || scope.newValue || "",
        "q[requires_weighing_eq]": +(this.unit !== "u"),
        "q[marijuana_eq]": +this.marijuana
      });

      if (!scope.newValue && !this.defaultProducts.length) {
        this.defaultProducts = this.products;
      }

      if (this.products.length === 0) {
        this.products = await productService.get({
          sort: "name",
          per_page: 10,
          "q[requires_weighing_eq]": +(this.unit !== "u"),
          "q[marijuana_eq]": +this.marijuana
        });
        this.defaultProducts = this.products;
      }

      if (this.products.length === 1) {
        this.$emit("input", {
          ...this.model,
          productObject: this.products[0],
          product: this.products[0].sku,
          strain: this.products[0].strain_id || ""
        });

        (this.$refs[
          "new-batch-form"
        ] as DynamicFormComponent).resetValidations();
      }
      return this.products;
    };
  }
}
