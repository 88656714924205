import LoadingWindowComponent from "@/components/metrc/loadingWindow/loadingWindow.component";
import { EventBus } from "@/event-bus";
import { BatchAction, CustomBatch, InventoryBatch } from "@/interfaces/batch";
import { BatchOperation } from "@/interfaces/batchOperations";
import { Product } from "@/interfaces/product";
import { Room } from "@/interfaces/room";
import { metrcEnabled } from "@/router.utils";
import { batchOperationsService } from "@/services/batchOperations.service";
import { productService } from "@/services/product.service";
import { strainService } from "@/services/strain.service";
import { CallbackPromise, PageNavAction } from "@/types/types";
import { getParentSKU, mapBatchesToAction } from "@/utils/batch-actions.utils";
import { ActionsSubheaderComponent } from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import keys from "lodash/keys";
import startsWith from "lodash/startsWith";
import Vue from "vue";
import Component from "vue-class-component";
import { Action, Getter } from "vuex-class";
import QuantitiesReservationComponent from "../common/QuantitiesReservation/QuantitiesReservation.component";
import Template from "./BatchCombine.template.vue";
import CombineFormComponent from "./CombineForm/CombineForm.component";
import {
  CombineOperationData,
  NewBatchFormModel,
  Result
} from "./model/batch-combine.model";

const NOT_APPLICABLE = "--";

@Component({
  mixins: [Template],
  components: {
    "quantities-reserv": QuantitiesReservationComponent,
    "combine-form": CombineFormComponent
  }
})
export default class BatchCombineComponent extends Vue {
  @Action("loadAllRooms", { namespace: "RoomModule" })
  public loadRooms!: CallbackPromise<void>;

  @Getter("allRooms", { namespace: "RoomModule" })
  public allRooms!: Room[];

  public canReserve = true;
  public combinationUnit = "u";
  public hasMetrc = metrcEnabled();

  /**
   * Original batches data.
   */
  public batches: InventoryBatch[] | null = null;

  /**
   * Form model.
   */
  public model: NewBatchFormModel = {
    product: null,
    strain: null,
    room: null,
    metrc_item: null,
    secondaryID: null,
    quantity: 0,
    productObject: null
  };

  /**
   * Base batch structure. It works as a prop.
   * Once set in created lifecycle it must not change
   * since it's the only source of truth.
   */
  public formattedBatches: BatchAction<CustomBatch> | null = null;

  public operationData: BatchOperation | null = null;
  public batchList: {
    [batchID: string]: CustomBatch[];
  } = {};

  /**
   * Current form step to show/hide components.
   */
  public step = 0;

  public results: Result[] = [];

  @Action("setPageNav", { namespace: "PageNavModule" })
  private setPageNav!: PageNavAction;

  private loading = false;

  public async created() {
    this.setPageNav({
      title: () =>
        this.step === 0 ? "batch_combine.title_reserve" : "batch_combine.title",
      isLoading: () => this.loading,
      rightActions: {
        generalActions: () => [
          {
            visibleCondition: () => this.step === 0,
            buttonText: "batch_combine.reserve",
            action: this.onReserve,
            vuetifyProps: () => ({
              loading: this.loading,
              disabled: !this.canReserve
            })
          },
          {
            visibleCondition: () => this.step === 1,
            buttonText: "batches.finish",
            action: this.onFinish,
            vuetifyProps: () => ({
              loading: this.loading
            })
          },
          {
            visibleCondition: () => this.step === 2,
            buttonText: "batch_combine.back",
            action: this.back,
            vuetifyProps: () => ({
              loading: this.loading
            })
          }
        ]
      },
      leftActions: {
        component: ActionsSubheaderComponent,
        props: {
          generalActions: [
            {
              visibleCondition: () => !this.step,
              icon: "fal fa-chevron-left",
              action: this.back,
              vuetifyProps: () => ({
                loading: this.loading,
                fab: true,
                small: true
              })
            }
          ]
        }
      }
    });

    this.batches = JSON.parse(this.$route.params.batches);
    this.formattedBatches = mapBatchesToAction(cloneDeep(this.batches!), true);
    this.combinationUnit = this.formattedBatches!.unit;

    if (!this.allRooms.length) {
      await this.loadRooms();
    }
  }

  /**
   * Routes the user back to inventory-view.
   * @returns void
   */
  public async back() {
    this.$router.back();
  }

  public async onReserve() {
    this.loading = true;
    this.operationData = await batchOperationsService.createOperation(
      this.formattedBatches!,
      "combine"
    );
    if (this.operationData) {
      this.buildBatchList();
      this.step++;
    }
    this.loading = false;
  }

  public onFinish() {
    this.loading = true;
    batchOperationsService
      .finishCombine(this.operationData!.operation_uid)
      .then(() => {
        this.back();
      })
      .finally(() => {
        this.loading = false;
      });
  }

  public async onCombine() {
    this.loading = true;
    if (
      this.hasMetrc &&
      this.model.productObject &&
      this.model.productObject.marijuana
    ) {
      this.$modals
        .load(LoadingWindowComponent, {
          closable: false,
          size: "fit",
          positionY: "center",
          positionX: "center",
          backdrop: true
        })
        .catch(() => {
          // nothing to do
        });
    }
    const res = await batchOperationsService.finishCombine(
      this.operationData!.operation_uid,
      this.model,
      this.hasMetrc
    );

    if (res && res.errors) {
      EventBus.$emit("mtrcLoadingEvent", {
        show: true,
        errorList: res.errors
      });
      this.loading = false;
      return;
    } else {
      EventBus.$emit("mtrcLoadingEvent", {
        show: false
      });
    }

    if (res) {
      this.operationData = res;
      const operationResult:
        | CombineOperationData[]
        | null = await batchOperationsService.getCombine(
        this.operationData!.operation_uid
      );
      await this.setSummary(operationResult);
      this.step = 2;
    }
    this.loading = false;
  }

  public updateReservationData(e: {
    reservation: BatchAction<CustomBatch>;
    valid: boolean;
  }) {
    this.formattedBatches = e.reservation;
    this.canReserve = e.valid;
  }

  protected buildBatchList() {
    const reservationItems = this.operationData!.reservation.reservation_items;
    const batchUIDs = keys(this.formattedBatches!.batches);
    this.batchList = reservationItems.reduce(
      (
        acc: {
          [batchID: string]: CustomBatch[];
        },
        item
      ) => {
        const batchUID = batchUIDs.find(b =>
          startsWith(item.batch_fraction_uid, b)
        );
        const itemInFormatted = this.formattedBatches!.batches[batchUID!].find(
          b => b.room_id === item.inventory_location_id
        );

        if (acc[batchUID!]) {
          acc[batchUID!].push({
            product_name: itemInFormatted!.product_name,
            product_sku: itemInFormatted!.product_sku,
            room_id: item.inventory_location_id,
            room_name: itemInFormatted!.room_name,
            room_quantity: item.quantity_value,
            room_quantity_editable: item.quantity_value,
            unit: item.quantity_unit,
            tracking_id: itemInFormatted!.tracking_id
          });
        } else {
          acc[batchUID!] = [
            {
              product_name: itemInFormatted!.product_name,
              product_sku: itemInFormatted!.product_sku,
              room_id: item.inventory_location_id,
              room_name: itemInFormatted!.room_name,
              room_quantity: item.quantity_value,
              room_quantity_editable: item.quantity_value,
              unit: item.quantity_unit,
              tracking_id: itemInFormatted!.tracking_id
            }
          ];
        }
        return acc;
      },
      {}
    );
  }

  protected async setSummary(operationResult: CombineOperationData[] | null) {
    if (operationResult) {
      const resultingFraction = operationResult[0].fractions[0];
      const combinationProduct: Product = await productService.findBySkuId(
        getParentSKU(operationResult[0].result!.sku)
      );

      const combinationStrain = operationResult[0].options.strain_id
        ? operationResult[0].options.strain_id === combinationProduct.strain_id
          ? combinationProduct.strain
          : await strainService.findById(operationResult[0].options.strain_id)
        : null;
      const decimals = +combinationProduct.requires_weighing * 2;
      this.results = [
        {
          title: "batch_combine.summary.product",
          value: combinationProduct.name
        },
        {
          title: "batch_combine.summary.strain",
          value: (combinationStrain && combinationStrain.name) || NOT_APPLICABLE
        },
        {
          title: "batch_combine.summary.batch_type",
          value:
            (combinationProduct.batch_type &&
              combinationProduct.batch_type.name) ||
            NOT_APPLICABLE
        },
        {
          title: "batch_combine.summary.batch_id",
          value: operationResult[0].result!.batch_uid || NOT_APPLICABLE
        },
        {
          title: "batch_combine.summary.secondary_id",
          value: operationResult[0].options.tracking_id || NOT_APPLICABLE
        },
        {
          title: "batch_combine.summary.room",
          value: this.allRooms.find(
            r => r.id === resultingFraction.inventory_location_id
          )!.name
        },
        {
          title: "batch_combine.summary.usable_weight",
          value: (combinationProduct.batches as InventoryBatch[]).reduce(
            (acc: string, item) => {
              if (
                item.batch_uid === operationResult[0].result!.batch_uid &&
                item.product_variant.marijuana &&
                !item.product_variant.requires_weighing
              ) {
                acc = `${item.product_variant.usable_weight_value} ${
                  item.product_variant.weight_per_unit_unit
                }`;
              }
              return acc;
            },
            NOT_APPLICABLE
          )
        },
        {
          title: "batch_combine.summary.quantity",
          value: resultingFraction
            ? `${resultingFraction.quantity.toFixed(
                decimals
              )} ${resultingFraction.unit || "u"}`
            : NOT_APPLICABLE
        }
      ];
    }
  }
}
