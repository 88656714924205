import { render, staticRenderFns } from "./BatchFormSubheader.template.vue?vue&type=template&id=7ec50797&scoped=true&"
var script = {}
import style0 from "./BatchFormSubheader.template.vue?vue&type=style&index=0&id=7ec50797&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ec50797",
  null
  
)

export default component.exports