import { currencyFilter } from "@/filters/currency.filter";
import { BatchDetail, ProductVariant } from "@/interfaces/batch";
import { Location } from "@/interfaces/location";
import {
  BatchLevelAssign,
  CompiledPrices,
  PriceGroup,
  PriceModel,
  TaxDetails
} from "@/interfaces/product";
import { TaxCategory } from "@/interfaces/taxCategory";
import { batchLevelService } from "@/services/batchLevel.service";
import { pricePointService } from "@/services/pricePoint.service";
import { taxCategoryService } from "@/services/taxCategory.service";
import { TableComponent } from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import findLast from "lodash/findLast";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./CostPricing.template.vue";
/**
 * batch basic info form component
 */
@Component({
  mixins: [Template],
  components: {
    TableComponent
  },
  filters: { currencyFilter }
})
export default class CostPricingComponent extends Vue {
  /**
   * model to save
   * @var Batch batch
   */
  @Prop({ required: true })
  public batch!: BatchDetail;

  @Prop({ required: true })
  public variant!: ProductVariant;

  @Prop({ required: true })
  public onDisplay!: boolean;

  @Prop({ default: false })
  public hasModifyPermission!: boolean;

  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;

  public currentPriceGroup: BatchLevelAssign | null = null;
  public currentPricing: CompiledPrices | null = null;
  public productPricing: CompiledPrices | null = null;
  public pricingPreview: CompiledPrices | null = null;
  public selectedPriceGroup: PriceGroup | null = null;
  public costPerUnit: number | null = null;
  public taxDetailsValue: TaxDetails[] = [];
  public taxCategories: TaxCategory[] = [];

  public dateFormatted: string | null = null;
  public batchLevels: PriceGroup[] = [];
  public totalCost: number = 0;
  public totalPotencialProfit: number = 0;
  public searchPrice: string | null = null;
  public batchLevelLoading = false;
  public loadingPrices = false;
  public defaultBatchLevel: PriceGroup[] = [];
  public searchPricesD = debounce(async (context, value: string) => {
    context.batchLevelLoading = true;
    context.batchLevels = await batchLevelService.findByName(value);
    context.batchLevelLoading = false;
  });

  private pricePoints: PriceModel[] = [];
  private potencialProfitPerUnit: number = 0;

  public async loadBatchLevels() {
    this.batchLevelLoading = true;
    this.batchLevels = await batchLevelService.get({
      "q[is_active_eq]": 1
    });
    this.batchLevelLoading = false;
    this.defaultBatchLevel = cloneDeep(this.batchLevels);
    if (this.currentPriceGroup) {
      this.selectedPriceGroup =
        this.batchLevels.find(
          b => b.id === this.currentPriceGroup!.pricing_group_id
        ) || null;
    }
  }

  public clearBatchPriceLevelFilter() {
    this.loadingPrices = true;
    setTimeout(() => {
      this.selectedPriceGroup = null;
      this.searchPrice = null;
      this.setBatchPrice();
      this.loadingPrices = false;
    }, 300);
  }

  public setTaxDetailsValue(currentUnit: string) {
    this.taxDetailsValue = pricePointService.taxDetailsValue(
      this.pricePoints,
      currentUnit
    );
  }

  public get headerTaxDetails() {
    return pricePointService
      .headerTaxDetails(this.pricePoints, this.loadingPrices)
      .filter(item => item.show);
  }

  public setBatchPrice() {
    this.$emit("priceChanged", {
      changed: Boolean(
        (this.currentPriceGroup &&
          this.selectedPriceGroup &&
          this.currentPriceGroup.pricing_group_id !==
            this.selectedPriceGroup.id!) ||
          (!this.currentPriceGroup && this.selectedPriceGroup) ||
          (this.currentPriceGroup && !this.selectedPriceGroup)
      ),
      priceGroup: this.selectedPriceGroup,
      currentPriceGroup: this.currentPriceGroup
    });
    this.fetchPreview();
  }

  public costPerUnitChanged() {
    this.calculateProfit();
    this.$emit("costPerUnitChanged", {
      cost_per_unit: this.costPerUnit,
      total_cost: this.totalCost
    });
  }
  protected async fetchPreview() {
    if (this.selectedPriceGroup) {
      this.loadingPrices = true;
      this.pricingPreview =
        this.currentPriceGroup &&
        this.currentPriceGroup.pricing_group_id === this.selectedPriceGroup.id
          ? this.currentPricing
          : await batchLevelService.getPricingPreview(
              this.batch.sku,
              this.selectedPriceGroup.id!
            );
      await this.loadPricesPoints(this.pricingPreview);
      this.loadingPrices = false;
    } else {
      await this.loadPricesPoints(this.productPricing);
    }
  }

  @Watch("searchPrice")
  protected searchPrices(val: string) {
    if (((val && val.length > 2) || !val) && !this.currentPriceGroup) {
      this.searchPricesD(this, val);
    }
  }

  protected calculateProfit() {
    if (this.pricePoints.length) {
      const usableWeight =
        (this.variant &&
          this.variant.price_settings.price_point_by_usable_weight &&
          this.variant.usable_weight_value) ||
        1;
      const appropriatePricePoint = findLast(
        this.pricePoints,
        p => p.quantity <= usableWeight
      );
      this.potencialProfitPerUnit =
        ((appropriatePricePoint &&
          +(
            (appropriatePricePoint.price / appropriatePricePoint.quantity) *
            usableWeight
          ).toFixed(2)) ||
          +this.pricePoints[0].price.toFixed(2) /
            this.pricePoints[0].quantity) - (this.costPerUnit || 0);
    }
    this.totalPotencialProfit =
      this.potencialProfitPerUnit * this.batch.total_quantity_value;
    this.totalCost = (this.costPerUnit || 0) * this.batch.total_quantity_value;
  }

  protected async loadPricesPoints(pricing: CompiledPrices | null) {
    let currentUnit = "--";
    if (pricing) {
      await this.setTaxCategories(pricing);
      const filtered = pricing.pricing.filter(
        p => p.pricing_type === this.currentLocation.location_type
      );
      currentUnit = (filtered.length && filtered[0].unit) || "--";
      this.pricePoints = filtered.reduce((acc: PriceModel[], rule) => {
        acc = acc.concat(
          rule.price_breaks!.map(p => ({
            ...p,
            tax_category_id: rule.tax_category_id!,
            tax_category: this.taxCategories.find(
              t => t.id === rule.tax_category_id
            ),
            member_discount_id: null,
            member_level_code: rule.member_level_code!,
            member_level_name: rule.member_level_name!
          }))
        );
        return acc;
      }, []);
    } else {
      this.pricePoints = [];
    }
    this.calculateProfit();
    this.setTaxDetailsValue(currentUnit);
  }

  protected async setTaxCategories(pricing: CompiledPrices) {
    const missingInMemory = pricing.pricing.reduce((acc: number[], rule) => {
      if (!this.taxCategories.find(t => t.id === rule.tax_category_id)) {
        acc.push(rule.tax_category_id!);
      }
      return acc;
    }, []);
    if (missingInMemory.length) {
      const taxCats = await taxCategoryService.get({
        "q[id_is_in]": missingInMemory
      });

      this.taxCategories = [...this.taxCategories, ...taxCats];
    }
  }

  protected mounted() {
    const unwatch = this.$watch("onDisplay", async () => {
      if (this.onDisplay) {
        this.loadingPrices = true;
        this.currentPriceGroup = await batchLevelService.getAssociatedPriceGroup(
          this.batch.sku
        );
        this.costPerUnit = this.variant.cost_per_unit || null;

        [this.currentPricing, this.productPricing] = await Promise.all([
          batchLevelService.getBatchPrices(this.batch.sku),
          batchLevelService.getBatchPrices(this.variant.concrete_sku)
        ]);

        this.loadBatchLevels();
        await this.loadPricesPoints(
          (this.currentPriceGroup && this.currentPricing) || this.productPricing
        );
        this.loadingPrices = false;
        unwatch();
      }
    });
  }
}
