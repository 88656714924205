import { SearchQuery } from "@/interfaces/httpQuery";
import { TaxCategory } from "@/interfaces/taxCategory";
import HttpService from "@/services/http.service";

class TaxCategoryService extends HttpService {
  protected loadDispatcher = "TaxCategoryModule/loadTaxCategories";
  protected uri: string = "sale/tax_categories";
  protected searchableField: SearchQuery[] = [
    {
      field: "name",
      type: "contains"
    }
  ];

  public async find(id: number): Promise<Location | null> {
    return await super.find(id);
  }

  /**
   * @return TaxCategory[]
   * @param query
   */
  public async get(query: object | null = null): Promise<TaxCategory[]> {
    const response = await super.get(query);
    return response.data.data;
  }

  public async getAll() {
    const locations = await super.get({ no_pagination: true });
    return locations.data;
  }
}

export const taxCategoryService: TaxCategoryService = new TaxCategoryService();
