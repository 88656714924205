import { i18n } from "@/plugins/i18n";
import { TableFieldType, TableHeader } from "helix-vue-components";

export const batchHistoryTable: TableHeader[] = [
  {
    value: "operation_uid",
    label: i18n.t("operation_id").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: false
  },
  {
    value: "operation_update_at",
    label: i18n.t("date").toString(),
    fieldComponent: TableFieldType.fnsDate,
    class: "tdt__headers__fieldLong",
    sortable: false
  },
  {
    value: "location.name",
    label: i18n.t("location").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: false
  },
  {
    value: "description",
    label: i18n.t("action").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: false
  },
  {
    value: "before_quantity/j/quantity_unit",
    label: i18n.t("batches.starting_quantity").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: false
  },
  {
    value: "modified_quantity/j/quantity_unit",
    label: i18n.t("batches.modified_quantity").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: false
  },
  {
    value: "after_quantity/j/quantity_unit",
    label: i18n.t("batches.new_quantity").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: false
  },
  {
    value: "user.first_name/j/user.last_name",
    label: i18n.t("user").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: false
  }
];

export const labResultTable: TableHeader[] = [
  {
    enum: {
      MOISTURE_CONTENT: "Moisture Content",
      FOREIGN_MATTER_INSPECTION: "Foreign Matter Inspection",
      MICROBIOLOGICAL_SCREENING: "Microbiological Screening",
      RESIDUAL_SOLVENT: "Residual Solvent",
      MYCOTOXIN_SCREENING: "Mycotoxin Screening",
      PESTICIDE_RESIDUE: "Pesticide Residue",
      HEAVY_METAL: "Heavy Metal",
      POTENCY: "Potency",
      TERPENE: "Terpene"
    },

    value: "profile.breakdown",
    label: i18n.t("testing_type").toString(),
    fieldComponent: TableFieldType.enumText,
    class: "tdt__headers__fieldLong",
    conditionalClassFn: () => "text-content",
    sortable: false,
    size: 35
  },
  {
    value: "profile.name",
    label: i18n.t("profile.profile").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: false,
    size: 21.6
  },
  {
    value: "value/j/unit_representation",
    label: i18n.t("result").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: false,
    size: 21.6
  }
];
