import { BatchType } from "@/interfaces/batchType";
import { Grade } from "@/interfaces/grade";
import {
  PriceGroup,
  PriceSetting,
  PricingRule,
  Product,
  SampleResult
} from "@/interfaces/product";
import { PRODUCT_UNIT } from "./batchOperations";
import { Room } from "./room";
import { Strain } from "./strain";
import { Type } from "./type";

export interface Batch {
  barcode_id?: string;
  quantities: {
    AVAILABLE?: number;
    RESERVED?: number;
    SOLD?: number;
  };
  _method?: string;
  account_id: number;
  attachments: File[];
  available: number;
  batch_logs?: any;
  batch_price_level_id?: number | null;
  batch_price_level?: PriceGroup | null;
  batch_type_id: string | null;
  batch_uid: string;
  batchsample: string;
  cost_per_unit: number;
  created_at: string;
  customer_transfer_id: string;
  deleted_at: string;
  destroy_reason: string | null;
  difference?: string;
  expiration_date: string | null;
  external_batch_number: string;
  fatherItem?: any;
  formatted_from_room?: string;
  grade_id?: number | null;
  grade?: Grade;
  id: number | null;
  in_store_quantity_unit: string;
  in_store_quantity_value: number;
  location_id: number;
  location?: string;
  medical_complaint: string | null;
  move_quantity?: number;
  ndc_number?: string;
  new_quantity?: number;
  note: string;
  package_date: string | null;
  package_start_date: string | null;
  product_id?: number;
  product_type_id: number;
  product_variant: ProductVariant;
  product?: Product;
  quantity: number;
  remaining_weight?: string;
  requires_weighing?: 1 | 0;
  room_quantity_formatted?: string;
  room_quantity?: number;
  room?: Room;
  rooms?: RoomQuantity[];
  sample?: BatchSample | null;
  secondary_id?: string;
  sku: string;
  status?: string;
  strain_id: number;
  strain_name?: string;
  strain?: Strain;
  time_in_location?: string;
  time_in_room?: string;
  total_cost: number;
  total_quantity?: string;
  total_tax: number;
  total: number;
  totalThcCbd?: string;
  tracking_id: string;
  transfer_type: string;
  type?: BatchType | Type;
  unit?: string;
  updated_at: string;
  usable_weight_unit: string;
  usable_weight_value: number;
  usable: number;
  vendor_id: number;
  weight_created: number;
  weight_remain: number;
  summary?: PBSummary[];
  is_multiple_room?: boolean;
  is_reserved?: boolean;
  by_pass_retail?: boolean;
  biotrack_traceability_id?: string;
  batchUid?: string;
  traceability_id?: string;
  weight_per_unit_unit?: string;
  weight_per_unit_value?: string;
  state_reporting_type?: {
    name: string;
  };
}

export const potencyModel = {
  attachments: []
};

export const batchDefault = {
  batch_uid: null,
  parent_id: null,
  child_id: null,
  batch_type_id: null,
  product: {
    marijuana: 0,
    batch_type: { name: "" },
    category: { name: "" },
    prices: []
  },
  room: {},
  account_id: 0,
  available: 0,
  quantity: 0,
  package_date: "",
  expiration_date: "",
  cost_per_unit: 0,
  created_at: "",
  deleted_at: "",
  destroy_reason: "",
  grade_id: 0,
  location_id: 0,
  medical_complaint: "",
  package_end_date: "",
  package_start_date: "",
  product_id: 0,
  product_type_id: 0,
  room_id: 0,
  strain_id: 0,
  total_cost: 0,
  total_tax: 0,
  total: 0,
  tracking_id: "",
  updated_at: "",
  usable: 0,
  usable_weight_value: 0,
  vendor_id: 0,
  weight_created: 0,
  weight_remain: 0,
  customer_transfer_id: "",
  transfer_type: "",
  // for new batch
  total_quantity_unit: "",
  total_quantity_value: null
};

export interface LaboratoryResult {
  id?: number;
  profile?: {
    id: number;
    name: string;
    type?: string;
    breakdown?:
      | "POTENCY"
      | "TERPENE"
      | "MOISTURE_CONTENT"
      | "FOREIGN_MATTER_INSPECTION"
      | "MICROBIOLOGICAL_SCREENING"
      | "RESIDUAL_SOLVENT"
      | "MYCOTOXIN_SCREENING"
      | "PESTICIDE_RESIDUE"
      | "HEAVY_METAL";
  } | null;
  laboratory_id?: number;
  laboratory?:
    | Laboratory
    | {
        id?: number;
        name?: string;
        license?: string;
        testing_date: string;
      };
  sample_id?: number;
  profile_id?: number;
  value?: number | null | string;
  unit_representation?: string;
  testing_date?: string | null;
  created_at?: string | Date;
  _destroy?: boolean;
}

export const defaultLaboratoryResult: LaboratoryResult = {
  laboratory_id: undefined,
  profile_id: undefined,
  value: null
};

export interface BatchSample {
  attachments?: File[];
  id?: number;
  thc: number;
  thc_relative?: number;
  cbd: number;
  cbd_relative?: number;
  thc_thca: number;
  cbd_cbda: number;
  terpene: number;
  terpene_relative?: number;
  batch_uid?: string;
  location_id?: number;
  account_id?: number;
  created_at?: string;
  updated_at?: string;
  laboratory?: Laboratory;
  laboratory_id: number | null;
  testing_date?: string;
  results: LaboratoryResult[];
}

export interface BatchSamplesModel {
  laboratory_id: number | null;
  testing_date: string | null;
  results: LaboratoryResult[];
}

export interface Laboratory {
  id: number;
  name: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  phone1: string;
  website: string;
  license: string;
  type: string;
  testing_date?: string;
  account_id: number;
  created_by: string;
  updated_by: string;
  deleted_by?: any;
  created_at: string;
  updated_at: string;
  parent_id: number;
  location_id: number;
}

export interface SampleResultProfile {
  id: number | null;
  name: string;
  type: string;
  breakdown?: "POTENCY" | "TERPENE";
}

export interface BatchTransfer {
  id: number | null;
  transfer_id: string;
  vendor_id: number | null;
  user_id: number | null;
  direction: string;
  updated_at: string;
  batches: Batch[];
  quantity: number | null;
  total: number | null;
  created_at: string;
}

/**
 * Used for ProductView mapper (data sent by route
 * to batch actions - combine, convert, etc.).
 */
export interface BatchAction<T> {
  // used in Combine action
  total_quantity: number;
  total_weighable?: number;
  total_weighable_unit?: string;
  total_countable?: number;
  total_countable_unit?: string;
  isMarijuana?: number;
  unit: string;
  batches: {
    [batchID: string]: T[];
  };
}
export interface MovedProduct {
  product: string;
  strain: string;
  batch_type: string;
  batch_uid: string;
  origin_room: string;
  new_room: string;
  usable_weight_value: string;
  quantity: string;
  biotrack_traceability_id?: string;
}

export interface BaseCustomBatch {
  batch_uid?: string;
  batch_fraction_uid?: string;
  product_name: string;
  room_id: number;
  room_name: string;
  room_quantity: number;
  room_quantity_editable: number;
  unit: PRODUCT_UNIT;
  tracking_id: string;
}

export interface ListBatch extends BaseCustomBatch {
  selected: boolean;
  room_ids?: number[];
  product_id?: number;
}

export interface CustomBatch extends BaseCustomBatch {
  batch_totals?: number;
  batch_type?: string;
  batch_totals_editable?: number;
  isMarijuana?: number;
  moved?: boolean;
  newRoom?: string;
  normal_price?: boolean;
  product_sku?: string;
  room_ids?: number[];
  room_totals?: number;
  room_totals_editable?: number;
  rules?: Array<(v: string) => boolean | string>;
  selected?: boolean;
  strain?: string;
  unit_usable_weighing?: PRODUCT_UNIT;
  usable_weight_value?: number;
  usable_weight_unit?: PRODUCT_UNIT;
  quantity?: number;
  requires_weighing?: boolean;
  reserved?: number;
  reserved_quantity?: number | null;
  biotrack_traceability_id?: string;
}

export interface BatchFraction {
  quantity_value: number;
  inventory_location_id: number;
}
export interface SplitPayload {
  from_inventory_location_id: number;
  inventory_locations: InventoryLocationSplit[];
}
export interface InventoryLocationSplit {
  batch_uid: string;
  quantity: number;
  inventory_location_id: number;
  tracking_id?: string;
}

export interface PayloadMove {
  batch_uid: string;
  from_inventory_location_id: number;
  to_inventory_location_id: number;
  quantity: number;
}

export interface RoomQuantity {
  id: number;
  batch_room_id: number;
  name: string;
  room_type: string;
  quantity: number;
  created_at: string;
  unit?: string;
}
export interface BatchOperationTypes {
  text: string;
  value: string | null;
}
export interface StockSummary {
  inventory_location: {
    id: number;
    name: string;
  };
  batches_quantity: number;
  available_quantity: number;
  batch_uids: string[];
}
export interface SamplesList {
  sampleResultsList1: SampleResult[];
  sampleResultsList2: SampleResult[];
}
export interface ProductBatch {
  status: "ACCEPTED" | "INCOMING" | "OUTGOING" | "REJECTED" | "SOLD" | "VOID";
  summary: PBSummary[];
  on_sale: any[];
  sku: string;
  batch_uid: string;
  updated_at: string;
  created_at: string;
  in_this_location_since: string;
  product_variant: ProductVariant;
  in_store_quantity_value: number;
  in_store_quantity_unit: string;
  total_quantity: string;
  remaining_weight?: string;
  tracking_id: string;
  biotrack_traceability_id: string;
  // When filtering by rooms this has the filtering logic
  on_rooms?: PBSummary[];
  batchUid?: string;
}

export interface ProductVariant {
  cost_per_unit?: number;
  total_cost?: number;
  total_tax?: number;
  usable_weight_value?: number;
  unit: string;
  usable_weight_unit: PRODUCT_UNIT;
  weight_per_unit_value?: number;
  weight_per_unit_unit: PRODUCT_UNIT;
  quantity?: number;
  barcode?: string;
  strain_id: number;
  strain: RelatedEntityMin;
  account_id: number;
  status: ProductStatus;
  package_date?: string;
  expiration_date?: string;
  sku: string;
  template_sku: string;
  entity_owner_id: number;
  store_view_id: number;
  marijuana: 0 | 1;
  pre_packaged: number;
  updated_at: string;
  created_at: string;
  brand?: RelatedEntityMin;
  requires_weighing?: boolean;
  price_settings: PriceSetting;
  concrete_sku: string;
  external_batch_number?: string;
  batch_type: {
    id: number;
    name: string;
  };
  name: string;
  pricing?: PricingRule[];
  category?: {
    name?: string;
    id?: string;
  };
  batch_type_id?: number;
}

interface ProductStatus {
  published: boolean;
  visible?: any;
  disabled?: any;
}

export interface RelatedEntityMin {
  id: number;
  name: string;
}

export interface PBSummary {
  room_quantity: number;
  in_this_inventory_location_since?: string;
  quantity_unit: string;
  quantity_value: number;
  batch_fraction_status_type: "AVAILABLE" | "RESERVED" | "BLOCKED" | "SOLD";
  inventory_location: Inventorylocation;
  inventory_location_id: number;
}

interface Inventorylocation {
  id: number;
  name: string;
  usage: string;
  type: string;
}

export interface InventoryBatch extends ProductBatch {
  product_name?: string;
  strain?: string;
  name?: string;
  is_selected?: boolean;
  new_batch_print: boolean;
  quantity_unit: string | undefined;
  inventory_location: {
    id: number;
    name: string;
  };
  batch_fraction_status_type: string;
  quantity_value: string | number;
  batches: [];
  id: number | null;
  biotrack_traceability_id: string;
  activation_time: string;
  activation_time_unit: string;
  time_in_location: string;
  expiration_date: string;
  strain_name: string;
  totalThcCbd: string;
  sample: BatchSample | null;
  room_quantity: number;
  room_quantity_formatted: string;
  fatherItem?: any;
  unit?: string;
  // Campos migrados del producto
  requires_weighing?: boolean;
  batch_type_id?: string | null;
  product_sku?: string;
  product?: Partial<Product>;
  // Para operations puede ser un InventoryBatch o un BatchDetail
  total_quantity_value?: number;
  total_quantity_unit?: string;
  quantities: {
    AVAILABLE?: number;
    RESERVED?: number;
  };
  isDone?: boolean;
  hasErrors: boolean;
  reserved_quantity?: number;
  room_quantity_available?: number;
  marijuana?: number;
  batchUid?: string;
  batch_sku?: string;
}

export interface BatchLog {
  batch_uid: string;
  sku: string;
  quantity_unit: string;
  before_quantity: number;
  after_quantity: number;
  operation_uid: string;
  operation_reference: string;
  operation_type_id: number;
  operation_update_at: string;
  operation_type: string;
  // UI Generated key
  operationDetail?: { [key: string]: OperationDetail[] };
}

export interface OperationDetail {
  before_status: string;
  after_status: string;
  before_quantity: number;
  after_quantity: number;
  inventory_location: string;
  inventory_location_id: number;
  operation_reference: string;
  quantity_unit: string;
  parents: string[];
  children: string[];
  children_trace: string[];
  parents_trace: string[];
  created_at: string;
}
export interface ProductVariantModel {
  usable_weight_value: number | null;
  usable_weight_unit: string | null;
  weight_per_unit_value: number | null;
  weight_per_unit_unit: string | null;
  strain_id: number | null;
  strain?: RelatedEntityMin | null;
  package_date: string | null;
  expiration_date: string | null;
  concretes?: Product[];
  tracking_id?: string | null;
  cost_per_unit: number | null;
  total_cost: number | null;
  [key: string]: any;
}

export interface AdditionalInfoModel {
  invoice_number: string | null;
  external_batch_number: string | null;
  custom_field_1: string | null;
  custom_field_2: string | null;
  custom_field_3: string | null;
  custom_field_4: string | null;
  [key: string]: string | null;
}

export interface BatchDetail {
  batch_uid: string;
  sku: string;
  tracking_id: string;
  total_quantity_unit: string;
  fraction_sequence: number;
  total_quantity_value: number;
  batch_status_type_id: number;
  batch_status_type: string;
  summary: PBSummary[];
  on_rooms: PBSummary[];
  marijuana: boolean;
}

export interface ReservationPayload {
  batch_uid: string;
  inventory_location_id: number;
  quantity: number;
  type?: string;
}
export interface PrintBarcode {
  batch?: { biotrack_traceability_id?: string };
  product_variant:
    | {
        marijuana: number;
        requires_weighing: number;
        batch_type: { name: string };
        strain: { name: string };
        category: { name: string };
        name: string;
        quantity_value: string;
      }
    | undefined;
  in_store_quantity_value: string;
  product_name: string;
  quantity_unit?: string;
  quantity_value?: string;
  product?: {
    marijuana: number;
    requires_weighing: number;
    batch_type: { name: string };
    strain: { name: string };
    category: { name: string };
    name: string;
    quantity_value: string;
  };
  batch_uid?: string;
  product_category?: string;
  batch_type?: string;
  strain?: string | null;
  quantity: string;
  barcodes?: string;
  inventory_labels?: string;
  id?: number | string;
  is_selected?: boolean;
  sku?: string[];
  biotrack_traceability_id?: string;
}
export interface PrintDataTable {
  biotrack_traceability_id: string;
  unit?: string;
  new_product: Product;
  batch_uid?: string;
  product_category?: string;
  batch_type: { name: string };
  strain?: { name: string };
  quantity: string;
  barcodes?: string | null;
  inventory_labels?: string;
  id?: number | string;
  is_selected?: boolean;
  old_batch_id?: string | number;
  new_batch_id?: string | number;
}
