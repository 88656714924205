import { BatchLog, ProductBatch } from "@/interfaces/batch";
import { User } from "@/interfaces/user";
import { batchHistoryTable } from "@/metadata/batch";
import { batchService } from "@/services/batch.service";
import { Callback } from "@/types/types";
import { batchActionFilters } from "@/utils/batch-actions.utils";
import {
  HelixDatePickerComponent,
  TableComponent,
  TableHeader,
  TablePagination,
  TablePaginationDefault
} from "helix-vue-components";
import debounce from "lodash/debounce";
import { TranslateResult } from "vue-i18n";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./BatchHistory.template.vue";
import OperationDetailComponent from "./operationDetail/OperationDetail.component";

@Component({
  mixins: [Template],
  components: {
    HelixDatePickerComponent,
    OperationDetailComponent,
    TableComponent
  }
})
export default class BatchHistoryComponent extends Vue {
  public headers: TableHeader[] = batchHistoryTable;

  @Getter("users", { namespace: "UserModule" })
  public storeUsersItems!: User[];
  @Getter("loading", { namespace: "UserModule" })
  public isLoading!: boolean;
  @Action("searchUsers", { namespace: "UserModule" })
  public searchUserAction!: Callback;

  public batchLogs: BatchLog[] = [];
  public searchUserField: string | null = null;
  public pagination: TablePagination = { ...TablePaginationDefault };
  public loading: boolean = true;
  public loadingDetail: boolean = false;
  public loadingActions: boolean = false;
  public actionItems: Array<{ value: string; text: TranslateResult }> = [];
  @Prop({ required: true })
  protected batch!: ProductBatch;
  @Prop({ required: true })
  protected onDisplay!: boolean;
  @Prop({ required: false })
  protected isModifiableBatches!: boolean;

  private debounceUserSearch = debounce(async (userName: string) => {
    if (!userName || userName.length > 2) {
      await this.searchUserAction(userName || "");
    }
  }, 500);

  private userIdFilter: number | null = null;
  private dateFilter: string | null = null;
  private actionFilter: string | null = null;

  @Watch("searchUserField")
  public async searchData(userName: string) {
    if (!this.userIdFilter) {
      await this.debounceUserSearch(userName);
      if (!userName) {
        this.getBatchLogs();
      }
    } else {
      this.getBatchLogs();
    }
  }

  public onActionChange() {
    this.getBatchLogs();
  }

  public async changePagination(pagination: any) {
    this.pagination = pagination;
    await this.getBatchLogs();
  }

  public async getBatchLogs(dateFilter?: string) {
    // Validation for date ,when we clear the date it is giving the same data , Added a validation if same date is there, send null .
    if (this.dateFilter === dateFilter) {
      this.dateFilter = null;
    } else {
      this.dateFilter = dateFilter!;
    }
    this.loading = true;
    const data = await batchService.getBatchLogs(
      this.batch.batch_uid,
      this.pagination,
      this.setParams()
    );
    this.pagination = await batchService.getPagination();
    this.batchLogs = data;
    this.loading = false;
  }

  public mounted() {
    const unwatch = this.$watch("onDisplay", () => {
      if (this.onDisplay) {
        this.loadActions();
        this.getBatchLogs();
        this.debounceUserSearch("");
        unwatch();
      }
    });
  }

  public async loadOperation(data: {
    index: number;
    isOpening: boolean;
    item: string;
  }) {
    const item = this.batchLogs[data.index];
    if (data.isOpening && !item.operationDetail) {
      this.loadingDetail = true;
      const detail = await batchService.getLogDetail(
        this.batch.batch_uid,
        data.item
      );
      this.batchLogs[data.index].operationDetail = detail;
      this.loadingDetail = false;
    }
  }

  protected loadActions() {
    this.loadingActions = true;
    this.actionItems = batchActionFilters();
    this.loadingActions = false;
  }

  private setParams() {
    return {
      "q[user_id_eq]": this.userIdFilter,
      "q[description_eq]": this.actionFilter,
      "q[created_at_eq]": this.dateFilter
    };
  }
}
