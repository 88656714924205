import AdditionalInfoComponent from "@/components/inventory/batchForm/additionalInfo/AdditionalInfo.component";
import BasicInfoComponent from "@/components/inventory/batchForm/basicInfo/BasicInfo.component";
import BatchHistoryComponent from "@/components/inventory/batchForm/batchHistory/BatchHistory.component";
import CostPricingComponent from "@/components/inventory/batchForm/costPricing/CostPricing.component";
import MetrcDetailsComponent from "@/components/inventory/batchForm/metrcDetails/MetrcDetails.component";
import PotencyTerpenesComponent from "@/components/inventory/batchForm/potencyTerpenes/PotencyTerpenes.component";
import LoadingWindowComponent from "@/components/metrc/loadingWindow/loadingWindow.component";
import BarcodeListComponent from "@/components/sharedComponents/print/barcodeList/barcodeList.component";
import InventoryLabelListComponent from "@/components/sharedComponents/print/inventoryLabelList/InventoryLabelList.component";
import { policyList } from "@/enums/permissions";
import {
  AdditionalInfoModel,
  BatchDetail,
  BatchSample,
  BatchSamplesModel,
  LaboratoryResult,
  ProductVariant,
  ProductVariantModel
} from "@/interfaces/batch";
import { BatchLevelAssign, PriceGroup, Product } from "@/interfaces/product";
import { ReconciliationPayload } from "@/interfaces/traceability";
import { EventBus } from "@/internal";
import { metrcEnabled } from "@/router.utils";
import { batchService } from "@/services/batch.service";
import { batchLevelService } from "@/services/batchLevel.service";
import { labResultsService } from "@/services/labResults.service";
import { messagesService } from "@/services/messages.service";
import { productService } from "@/services/product.service";
import { traceabilityService } from "@/services/traceability.service";
import { Callback, PageNavAction } from "@/types/types";
import {
  batchFormatedforLabResults,
  getParentSKU
} from "@/utils/batch-actions.utils";
import { BooleanCheck, CallbackPromise } from "helix-vue-components";
import every from "lodash/every";
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";
import omitBy from "lodash/omitBy";
import reduce from "lodash/reduce";
import { Validator } from "vee-validate";
import { Component, Inject, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./BatchForm.template.vue";
import { BatchFormSubheaderComponent } from "./BatchFormSubheader/BatchFormSubheader.component";

const namespace = "BatchModule";

interface CostPricingEvent {
  changed: boolean;
  currentPriceGroup: BatchLevelAssign | null;
  priceGroup: PriceGroup | null;
}

enum batchTabs {
  batchDetails = "batch-details",
  additionalInfo = "aditonal-details",
  costPricing = "cost-pricing",
  potencyTerpenes = "potency-terpenes",
  batchHistory = "batch-history",
  metrcInfo = "metrc-info"
}

@Component({
  mixins: [Template],
  components: {
    AdditionalInfoComponent,
    BasicInfoComponent,
    CostPricingComponent,
    PotencyTerpenesComponent,
    BatchHistoryComponent,
    MetrcDetailsComponent
  }
})
export default class BatchFormComponent extends Vue {
  @Inject("$validator") public $validator!: Validator;
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  public tab = batchTabs.batchDetails;
  public loading = false;
  public loadingSamples = false;
  public loadingWindow = false;
  public policyList = policyList;
  public product: Product | null = null;
  public basicInfoChanged = false;
  public batchSamples: BatchSample | null = null;
  public resStack: any[] = [];
  public labAttachments: File[] = [];
  public recoRes: { response: any; success: boolean } | null = null;
  public replaceNeeded: boolean = false;
  public hasModifyPermission: boolean = false;
  public batchTabs = batchTabs;
  public reconciliationData: {
    payload: ReconciliationPayload;
    hasChanged: boolean;
  } | null = null;
  public labModel: {
    hasResults: boolean;
    newResults: LaboratoryResult[];
  } = {
    hasResults: false,
    newResults: []
  };

  public productVariant: ProductVariant | null = null;
  public variantModel: ProductVariantModel = {
    usable_weight_value: null,
    usable_weight_unit: null,
    weight_per_unit_value: null,
    weight_per_unit_unit: null,
    strain_id: null,
    strain: null,
    package_date: null,
    expiration_date: null,
    cost_per_unit: null,
    total_cost: null
  };

  public additionalInfoModel: AdditionalInfoModel = {
    invoice_number: null,
    external_batch_number: null,
    custom_field_1: null,
    custom_field_2: null,
    custom_field_3: null,
    custom_field_4: null
  };

  public priceStatus: CostPricingEvent = {
    changed: false,
    currentPriceGroup: null,
    priceGroup: null
  };
  public statusTestingType = ["RESIDUAL_SOLVENT", "PESTICIDE_RESIDUE"];

  public hasMetrc = metrcEnabled();

  /**
   * @getter to get current batch
   */
  @Getter("currentBatch", { namespace })
  public batch!: BatchDetail | null;

  /**
   * Getter  of Permission Module
   * @returns a method that check if a given policy is present in the user policy list
   */
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;

  /**
   * @method to get current Batch
   */
  @Action("findBatch", { namespace })
  private findBatch!: CallbackPromise<void>;

  /**
   * @method to unset current Batch
   */
  @Action("unsetCurrentBatch", { namespace })
  private unsetBatch!: Callback;

  public cancel() {
    EventBus.$emit("detailsBatches.products");
    this.$router.back();
  }

  public async getSamples() {
    if (!this.batchSamples && this.batch && !this.loadingSamples) {
      this.loadingSamples = true;
      this.batchSamples = await labResultsService.getBatchSamples(
        this.batch.batch_uid
      );

      this.loadingSamples = false;
    }
  }

  public async printLabel() {
    await this.getSamples();
    const hasLab = !!this.batchSamples!.laboratory;
    this.batchSamples!.results.map(item => {
      if (this.statusTestingType.includes(item.profile!.breakdown!)) {
        item.value = "";
        item.unit_representation =
          item.unit_representation === "0" ? "Pass" : "Fail";
      } else {
        item.value = +parseFloat(String(item.value)).toFixed(3);
      }
    });

    EventBus.$emit("print", {
      component: InventoryLabelListComponent,
      props: {
        batches: [
          {
            ...this.batch,
            laboratory: hasLab
              ? batchFormatedforLabResults(this.batchSamples!)
              : null,
            sample: hasLab ? this.batchSamples : null,
            product_variant: this.productVariant,
            product: this.product,
            fatherItem: {
              laboratory_name: hasLab
                ? this.batchSamples!.laboratory!.name
                : "N/A",
              laboratory_license: hasLab
                ? this.batchSamples!.laboratory!.license
                : "N/A",
              laboratory_testing_date: hasLab
                ? this.batchSamples!.testing_date
                : "N/A"
            }
          }
        ],
        labelType: "INVENTORY"
      }
    });
  }

  public printBarcode() {
    EventBus.$emit("print", {
      component: BarcodeListComponent,
      props: {
        batches: [
          {
            ...this.batch,
            product_variant: this.productVariant,
            product: this.product
          }
        ]
      }
    });
  }

  public async updateLabResults(event: {
    hasResults: boolean;
    samples: BatchSamplesModel;
  }) {
    this.loadingSamples = true;
    let response = null;
    // Save Lab Results
    if (event.hasResults) {
      response = await labResultsService.updateLabResult(
        this.batch!.batch_uid,
        event.samples
      );
    } else {
      response = await labResultsService.addLabResult(
        this.batch!.batch_uid,
        event.samples
      );
    }
    this.loadingSamples = false;
    if (response) {
      this.batchSamples = null;
      this.getSamples();
    }
  }

  public priceChanged(e: CostPricingEvent) {
    this.priceStatus = e;
  }

  public labFilesChanged(filesToSave: File[]) {
    this.labAttachments = filesToSave;
  }

  public costPerUnitChanged(e: { cost_per_unit: string; total_cost: number }) {
    this.variantModel.cost_per_unit = e.cost_per_unit ? +e.cost_per_unit : null;
    this.variantModel.total_cost = e.total_cost;
    this.basicInfoChanged = true;
  }

  public reconciliationChanged(data: {
    payload: ReconciliationPayload;
    hasChanged: boolean;
  }) {
    this.reconciliationData = data;
  }

  public replace(newProduct: Product) {
    this.replaceNeeded = true;
    this.product = newProduct;
  }

  protected async saveBatch() {
    const valid = await Promise.all([
      (this.$validator.validateAll(),
      (this.$refs.basicInfo as BasicInfoComponent).$validator.validateAll())
    ]);
    if (every(valid, Boolean)) {
      this.loading = true;
      this.resStack = [];
      if (this.basicInfoChanged) {
        const res = await batchService.updateProductVariant(this.batch!.sku, {
          ...this.variantModel,
          ...this.additionalInfoModel
        });
        this.resStack.push(res);
      }
      // upload lab attachments
      if (this.labAttachments.length) {
        this.labAttachments.forEach(file =>
          labResultsService.addAttachment(this.batch!.batch_uid, file)
        );
      }
      // update secondary ID
      if (
        this.variantModel.tracking_id &&
        this.batch!.tracking_id !== this.variantModel.tracking_id
      ) {
        batchService.updateBatch(this.batch!.batch_uid, {
          tracking_id: this.variantModel.tracking_id
        });
      }
      // update Prices
      if (this.priceStatus.changed) {
        if (this.priceStatus.priceGroup) {
          if (this.priceStatus.currentPriceGroup) {
            batchLevelService.updateBatchPriceGroup(
              this.priceStatus.currentPriceGroup.id,
              {
                pricing_group_id: this.priceStatus.priceGroup.id!,
                code: this.batch!.sku
              }
            );
          } else {
            batchLevelService.addPriceGroupToBatch({
              pricing_group_id: this.priceStatus.priceGroup.id!,
              code: this.batch!.sku
            });
          }
        } else if (this.priceStatus.currentPriceGroup) {
          batchLevelService.deleteBatchPriceGroup(
            this.priceStatus.currentPriceGroup.id
          );
        }
      }
      // Change Product
      if (this.replaceNeeded && this.product && this.batch) {
        await productService.setReplacementProduct(
          this.product.sku,
          this.batch.batch_uid
        );
      }
      // Batch reconciliation
      if (
        this.hasMetrc &&
        this.reconciliationData &&
        this.reconciliationData.hasChanged
      ) {
        const body: Partial<ReconciliationPayload> = omitBy(
          JSON.parse(JSON.stringify(this.reconciliationData.payload)),
          isEmpty
        );
        if (body.remote) {
          this.loadingWindow = true;
          this.$modals
            .load(LoadingWindowComponent, {
              closable: false,
              size: "fit",
              positionY: "center",
              positionX: "center",
              backdrop: true
            })
            .catch(() => {
              // nothing to do
            });
        }
        const metrcResponse = await traceabilityService.reconcileBatch(
          body,
          this.loadingWindow
        );
        if (metrcResponse && metrcResponse.errors) {
          EventBus.$emit("mtrcLoadingEvent", {
            show: true,
            errorList: metrcResponse.errors || []
          });
          this.recoRes = { response: metrcResponse, success: false };
        } else {
          if (body.local && body.local.adjust) {
            await this.findBatch({ uid: this.$route.params.id });
          }
          EventBus.$emit("mtrcLoadingEvent", {
            show: false
          });
          this.recoRes = { response: metrcResponse, success: true };
        }
        this.loading = false;
        return;
      }
      this.loading = false;
      if (every(this.resStack, Boolean)) {
        messagesService.renderSuccessMessage("batches.success_msg");
        this.cancel();
      }
    } else {
      this.tab = this.batchTabs.batchDetails;
    }
  }

  protected async setBasicInfoTabModel() {
    if (this.batch && this.batch.sku) {
      this.productVariant = await batchService.getProductVariant(
        this.batch.sku
      );
      if (this.productVariant) {
        this.variantModel = reduce(
          this.productVariant,
          (result, value, key) => {
            if (has(this.variantModel, key)) {
              result[key] = value;
            }
            if (has(this.additionalInfoModel, key)) {
              this.additionalInfoModel[key] = value as string | null;
            }
            return result;
          },
          this.variantModel
        );
        this.variantModel.tracking_id = this.batch!.tracking_id || null;
      }

      if (
        this.variantModel.weight_per_unit_value === null &&
        this.productVariant &&
        this.productVariant!.weight_per_unit_value
      ) {
        this.variantModel.weight_per_unit_value = +this.productVariant
          .weight_per_unit_value;
        this.variantModel.weight_per_unit_unit = this.productVariant.weight_per_unit_unit;
      }
    }
  }

  protected async mounted() {
    this.hasModifyPermission = !this.hasPermission(
      policyList.modifyBatchDetails
    );
    this.loading = true;
    this.setPageNav({
      title: "batch_details",
      isLoading: () => this.loading,
      rightActions: {
        generalActions: () => [
          {
            icon: "fal fa-check",
            action: this.saveBatch,
            vuetifyProps: () => ({
              loading: this.loading,
              fab: true,
              small: true
            })
          },
          {
            icon: "fal fa-times",
            action: this.cancel,
            vuetifyProps: () => ({
              loading: this.loading,
              fab: true,
              small: true
            })
          }
        ]
      },
      secondaryActions: {
        component: BatchFormSubheaderComponent,
        props: {
          batchData: () => this.batch,
          loading: () => this.loading,
          barcodeCallback: this.printBarcode,
          labelCallback: this.printLabel,
          productData: () => this.product,
          variantData: () => this.productVariant
        },
        events: [
          {
            event: "reject",
            callback: () => this.$emit("reject")
          }
        ]
      }
    });
    await this.findBatch({ uid: this.$route.params.id });
    const [product, _] = await Promise.all([
      productService
        .findBySkuId(getParentSKU(this.batch!.sku))
        .catch(e => null),
      this.setBasicInfoTabModel()
    ]);
    this.product = product;
    if (!this.batch || !this.product) {
      this.$router.push({ name: "products" });
      return;
    }

    this.loading = false;
  }

  protected destroyed() {
    this.unsetBatch();
  }
}
