import { policyList } from "@/enums/permissions";
import { BatchDetail, ProductVariant } from "@/interfaces/batch";
import { TRANSFER_TYPES } from "@/interfaces/batchTransferManager";
import { InventorySettings } from "@/interfaces/inventorySettings";
import { Product } from "@/interfaces/product";
import { RetailSettings } from "@/interfaces/retailSettings";
import { batchService } from "@/services/batch.service";
import { retailSettingsService } from "@/services/retailSettings.service";
import { Callback } from "@/types/types";
import { BooleanCheck } from "helix-vue-components";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./BatchFormSubheader.template.vue";
@Component({
  mixins: [Template]
})
export class BatchFormSubheaderComponent extends Vue {
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public retailSettings!: RetailSettings;
  @Prop({ required: true }) public batchData!: () => BatchDetail;
  @Prop({ required: true }) public barcodeCallback!: Callback;
  @Prop({ required: true }) public labelCallback!: Callback;
  @Prop({ required: true }) public loading!: () => boolean;
  @Prop({ required: true }) public variantData!: () => ProductVariant;
  @Prop({ required: true }) public productData!: () => Product;
  @Getter("currentInventorySettings", { namespace: "AuthModule" })
  public inventorySetting!: InventorySettings | null;
  public policyList = policyList;
  public outgoingTransfer = TRANSFER_TYPES.OUTGOING;
  @Getter("hasBioTrackTraceIntegrations", { namespace: "AuthModule" })
  public hasBioTrackTraceIntegrations!: boolean;
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;
  public get batch() {
    return this.batchData();
  }
  public get getProductData() {
    return this.productData();
  }

  public get variant() {
    return this.variantData();
  }

  public get isReserved() {
    this.batch.summary = this.batch.summary.filter(
      item =>
        !(
          item.batch_fraction_status_type === "AVAILABLE" &&
          item.quantity_value === 0
        )
    );
    const reserve = this.batch.summary.every(
      item => item.batch_fraction_status_type !== "AVAILABLE"
    );
    return reserve;
  }

  public actionBatch(action: string) {
    this.$router.push({
      name: action,
      params: {
        batches: JSON.stringify([
          {
            ...this.batch,
            product_variant: this.variantData(),
            product: this.productData()
          }
        ])
      }
    });

    this.$emit("reject");
  }

  public async mounted() {
    const biotrackEnable = await retailSettingsService.findByLocationId(
      this.$data.id
    );
    this.bioTrackTraceEnabled =
      this.hasBioTrackTraceIntegrations && this.bioTrackTraceEnabled;
  }
}
