import { OperationDetail } from "@/interfaces/batch";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./OperationDetail.template.vue";

@Component({
  mixins: [Template]
})
export default class OperationDetailComponent extends Vue {
  @Getter("hasBioTrackTraceIntegrations", { namespace: "AuthModule" })
  public hasBioTrackTraceIntegrations!: boolean;
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;
  @Prop({ default: false })
  public loading!: boolean;
  @Prop({ required: true })
  public item!: { [key: string]: OperationDetail[] };

  public convertArrayToString(arr: string[]) {
    return arr.length ? arr.join(", ") : "";
  }
  public getRoom(room: OperationDetail[]) {
    const quantity = room.map(qty => qty.quantity_unit);
    room = room.filter(
      status =>
        status.before_status === "RESERVED" ||
        (status.before_status === "AVAILABLE" &&
          status.after_status === "AVAILABLE" &&
          status.inventory_location_id === 2)
    );
    if (this.bioTrackTraceEnabled && room.length) {
      if (room[0].children_trace[0] !== "") {
        room[0].children = room[0].children_trace;
      }
      if (room[0].parents_trace[0] !== "") {
        room[0].parents = room[0].parents_trace;
      }
    }
    return room.length
      ? room
      : [
          {
            reserved_quantity: 0,
            quantity_unit: quantity[0]
          }
        ];
  }
}
