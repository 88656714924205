import CanvasTreeComponent from "@/components/templateEditor/canvas/canvas-tree/CanvasTree.component";
import TemplateEditor from "@/components/templateEditor/editors.declaration";
import { currencyFilter } from "@/filters/currency.filter";
import { InventoryBatch } from "@/interfaces/batch";
import { Location } from "@/interfaces/location";
import { MinPrice, PackageDetail } from "@/interfaces/product";
import { User } from "@/interfaces/user";
import { batchLevelService } from "@/services/batchLevel.service";
import { messagesService } from "@/services/messages.service";
import { templatesService } from "@/services/templates.service";
import size from "lodash/size";
import uniq from "lodash/uniq";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./InventoryLabelList.template.vue";

@Component({
  mixins: [Template],
  components: { CanvasTreeComponent }
})
export default class InventoryLabelListComponent extends Vue {
  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;
  @Getter("user", { namespace: "AuthModule" })
  public user!: User;

  @Prop({ required: true })
  public batches!: InventoryBatch[];
  public batchesTemplates: TemplateEditor.TemplateModel[] = [];
  public templatesObject: { [key: string]: any } = {};
  public defaultTemplate: TemplateEditor.TemplateModel | null = null;
  public skus: string[] = [];
  public batchPrices: { [key: string]: MinPrice | null } = {};
  @Getter("hasBioTrackTraceIntegrations", { namespace: "AuthModule" })
  public hasBioTrackTraceIntegrations!: boolean;
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;

  public getBatchLabelId(batch: InventoryBatch) {
    const packaging = this.getPackaging(batch);
    return (packaging && packaging.inventory_label_template_id) || null;
  }

  public getBatchPrice(sku: string): MinPrice | null {
    const price = this.batchPrices[sku];
    if (price) {
      return {
        ...price,
        pre_formatted: `${price.quantity}@${currencyFilter(
          price.price_pre_tax
        )}`,
        post_formatted: `${price.quantity}@${currencyFilter(
          price.price_post_tax
        )}`
      };
    }
    return null;
  }

  protected getPackaging(batch: InventoryBatch): PackageDetail | null {
    return (
      (batch.fatherItem && batch.fatherItem.packaging_detail) ||
      (batch.product && batch.product.packaging_detail) ||
      null
    );
  }

  protected async created() {
    this.batches.map((batch: InventoryBatch) => {
      if (this.bioTrackTraceEnabled && batch.product!.marijuana === 1) {
        batch.batch_uid = batch.biotrack_traceability_id!;
      } else {
        batch.batch_uid = batch.batch_uid;
      }
    });
    this.user.name = `${this.user.first_name} ${this.user.last_name}`;
    let templateIds = uniq(
      this.batches.map((batch: InventoryBatch) => {
        this.skus.push(batch.sku);
        return this.getBatchLabelId(batch);
      })
    );

    if (templateIds.includes(null)) {
      this.defaultTemplate = await templatesService.getDefault("INVENTORY");
    }
    templateIds = templateIds.filter(id => {
      return id !== null;
    });

    if (templateIds.length) {
      this.batchesTemplates = await templatesService.get({
        "q[id_is_in]": templateIds
      });

      this.templatesObject =
        (this.batchesTemplates &&
          this.batchesTemplates.reduce(
            (acc, current) => ({ ...acc, [current.id]: current }),
            {}
          )) ||
        {};
    }

    // Fetch Batch prices
    if (this.skus[0]) {
      this.batchPrices = await batchLevelService.getMinBatchPrices(this.skus);
    }

    if (this.defaultTemplate || size(this.templatesObject)) {
      this.$nextTick(() => this.$emit("readyToPrint"));
    } else {
      messagesService.renderWarningMessage("no_template_labels");
    }
  }
}
